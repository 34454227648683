import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import { PATH_NAMES } from "../../../lib/router/utils/router";

interface HomeServiceContactButtonProps {
  label: string;
  isLeftAligned?: boolean;
}

function HomeServiceContactButton({
  label,
  isLeftAligned = false,
}: HomeServiceContactButtonProps) {
  const navigate = useNavigate();

  return (
    <ButtonWrapper
      isLeftAligned={isLeftAligned}
      onClick={() => navigate(PATH_NAMES.CONTACT.path)}
    >
      <Button>{label}</Button>
    </ButtonWrapper>
  );
}

interface ButtonWrapperProps extends ThemedComponentProps {
  isLeftAligned: boolean;
}

const ButtonWrapper = styled.div<any>(
  ({ isLeftAligned }: ButtonWrapperProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: isLeftAligned ? "flex-start" : "center",
  })
);

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px ${(theme.spacing.section * 3) / 2}px`,
  border: `1px solid ${theme.color.goldText}`,
  backgroundColor: theme.color.black,
  fontSize: theme.font.body2,
  color: theme.color.goldText,

  "&:hover": {
    backgroundColor: theme.color.gold,
    color: theme.color.black,
  },

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.small}px ${theme.spacing.section}px`,
    fontSize: theme.font.caption1,
  },
}));

export default HomeServiceContactButton;
