import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import { HomeServiceProps } from "./HomeService";
import arrowIcon from "../../../assets/icons/chevron_down_white.svg";
import summaryImage1 from "../../../assets/images/service_summary_1.webp";
import summaryImage2 from "../../../assets/images/service_summary_2.webp";
import summaryImage3 from "../../../assets/images/service_summary_3.webp";
import summaryImage4 from "../../../assets/images/service_summary_4.webp";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import { HomeSubtitle, HomeTitle } from "../common/styles/homeCommonStyles";

const SUMMARIES = [
  {
    number: "01",
    title: "home.serviceSummary1Title",
    subtitle: "home.serviceSummary1Subtitle",
    image: summaryImage1,
  },
  {
    number: "02",
    title: "home.serviceSummary2Title",
    subtitle: "home.serviceSummary2Subtitle",
    image: summaryImage2,
  },
  {
    number: "03",
    title: "home.serviceSummary3Title",
    subtitle: "home.serviceSummary3Subtitle",
    image: summaryImage3,
  },
  {
    number: "04",
    title: "home.serviceSummary4Title",
    subtitle: "home.serviceSummary4Subtitle",
    image: summaryImage4,
  },
];

const edgeLists = [
  "borderTopLeftRadius",
  "borderTopRightRadius",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",
];

function HomeServiceSummary({
  service1Ref,
  service2Ref,
  service3Ref,
  service4Ref,
  serviceSummaryRef,
}: HomeServiceProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Wrapper ref={serviceSummaryRef}>
      <HomeSubtitle>{t("home.serviceSummarySubtitle")}</HomeSubtitle>
      <HomeTitle>
        <div>{t("home.serviceSummaryTitle1")}</div>
        <div>{t("home.serviceSummaryTitle2")}</div>
      </HomeTitle>
      <SummaryBoxesWrapper>
        {SUMMARIES.map((summary, index) => {
          let edges: React.CSSProperties = {};

          if (isMobile) {
            if (index === 0) {
              edges = { [edgeLists[0]]: 28, [edgeLists[1]]: 28 };
            }
            if (index === SUMMARIES.length - 1) {
              edges = { [edgeLists[2]]: 28, [edgeLists[3]]: 28 };
            }
          } else {
            edges = { [edgeLists[index]]: 28 };
          }

          const serviceRef =
            index === 0
              ? service1Ref
              : index === 1
              ? service2Ref
              : index === 2
              ? service3Ref
              : service4Ref;

          return (
            <SummaryBox
              key={summary.number}
              image={summary.image}
              edges={edges}
            >
              <SummaryBoxNumber>{summary.number}</SummaryBoxNumber>
              <SummaryTitle>{t(summary.title)}</SummaryTitle>
              <SummarySubtitle>{t(summary.subtitle)}</SummarySubtitle>
              <SummaryButton
                onClick={() =>
                  serviceRef?.current?.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                <div>{t("common.viewMore")}</div>
                <SummaryButtonIcon />
              </SummaryButton>
            </SummaryBox>
          );
        })}
      </SummaryBoxesWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.section * 3}px ${theme.spacing.large}px`,
  width: "100%",
  maxWidth: theme.appMaxWidth,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  [theme.media.down["DESKTOP"]]: {
    padding: `${(theme.spacing.section * 3) / 2}px ${theme.spacing.large}px`,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.default}px`,
  },
}));

const SummaryBoxesWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing.default,

    [theme.media.down["TABLET"]]: {
      gridTemplateColumns: "1fr",
    },
  })
);

interface SummaryBoxProps extends ThemedComponentProps {
  image: string;
  edges: React.CSSProperties;
}

const SummaryBox = styled.div<any>(
  ({ theme, image, edges }: SummaryBoxProps) => ({
    padding: "64px 60px",
    background: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: theme.color.black,
    ...edges,
    textAlign: "left",

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.large}px ${theme.spacing.middle}px`,
    },
  })
);

const SummaryBoxNumber = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontFamily: "Playfair Display",
  fontSize: 22,
  marginBottom: theme.spacing.normal,

  [theme.media.down["DESKTOP"]]: {
    fontSize: 12,
    marginBottom: theme.spacing.small,
  },
}));

const SummaryTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head1,
  fontWeight: "bold",
  marginBottom: theme.spacing.xSmall / 2,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body1,
    marginBottom: 0,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body2,
    marginBottom: 0,
  },
}));

const SummarySubtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  marginBottom: 64,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.middle,
  },
}));

const SummaryButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
  display: "flex",
  alignItems: "center",
  fontSize: theme.font.body3,
  fontWeight: "bold",
  color: theme.color.white,
  backgroundColor: theme.color.black,
  gap: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    padding: `6px ${theme.spacing.small}px`,
    gap: theme.spacing.xSmall / 2,
    fontSize: theme.font.caption2,
  },
}));

const SummaryButtonIcon = styled.img.attrs(() => ({
  src: arrowIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 24,
  height: 24,

  [theme.media.down["DESKTOP"]]: {
    width: 16,
    height: 16,
  },
}));

export default HomeServiceSummary;
