import React from "react";
import { useTranslation } from "react-i18next";
import { HomeServiceItemProps } from "./HomeService1";
import HomeServiceContactButton from "./HomeServiceContactButton";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import styled, {
  DefaultTheme,
  ThemedComponentProps,
  useTheme,
} from "styled-components";
import {
  HomeServiceNumbering,
  HomeServiceSubtitle,
  HomeServiceTitle,
} from "../common/styles/homeCommonStyles";

function HomeService34({ serviceRef, service }: HomeServiceItemProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isTablet, isMobile } = useScreenWidthContext();

  return (
    <Wrapper ref={serviceRef}>
      <div>
        <HomeServiceNumbering>{service.id}</HomeServiceNumbering>
        <HomeServiceTitle
          style={{
            marginBottom: isMobile
              ? theme.spacing.default
              : isTablet
              ? theme.spacing.normal
              : theme.spacing.middle,
          }}
        >
          {t(service.title)}
        </HomeServiceTitle>
        <HomeServiceSubtitle
          style={{
            marginBottom: isMobile
              ? theme.spacing.middle
              : isTablet
              ? theme.spacing.middle
              : theme.spacing.section,
          }}
        >
          {t(service.description)}
        </HomeServiceSubtitle>
      </div>
      <div>
        <HomeServiceContactButton label={t("common.contact")} isLeftAligned />

        <Spacer />

        <Image alt={service.alt} src={service.image} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.regular * 5}px ${theme.spacing.section}px`,
  paddingBottom: theme.spacing.section * 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flex: 1,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.regular * 3}px ${theme.spacing.regular}px`,
    paddingBottom: theme.spacing.section * 2,
  },

  [theme.media.down["TABLET"]]: {
    padding: 0,
  },
}));

const Spacer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  height: theme.spacing.section,

  [theme.media.down["DESKTOP"]]: {
    height: theme.spacing.middle,
  },
}));

const Image = styled.img<any>(() => ({
  width: "100%",
  objectFit: "contain",
}));

export default HomeService34;
