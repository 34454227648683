import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import contactUsImageMobile from "../../../assets/images/contactus_image_mobile.webp";
import contactUsImageTablet from "../../../assets/images/contactus_image_tablet.webp";
import contactUsImage from "../../../assets/images/contactus_image.webp";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

function HomeContactUs() {
  const { t } = useTranslation();
  const { isTablet, isMobile } = useScreenWidthContext();

  return (
    <Wrapper image={isMobile ? contactUsImageMobile : contactUsImage}>
      {!isMobile && <Col />}
      <Col>
        <Content>
          <Title>{t("home.contactUsTitle")}</Title>
          <Email>footsketch@footsketch.com</Email>
          <EmailButton href={`mailto:footsketch@footsketch.com`}>
            {t("home.contactUsEmail")}
          </EmailButton>
        </Content>
      </Col>
    </Wrapper>
  );
}

interface WrapperProps extends ThemedComponentProps {
  image?: string;
}

const Wrapper = styled.div<any>(({ theme, image }: WrapperProps) => ({
  background: `url(${image})`,
  height: 513,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.media.down["DESKTOP"]]: {
    height: 225,
  },

  [theme.media.down["TABLET"]]: {
    height: 272,
  },
}));

const Col = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  flexBasis: "50%",

  [theme.media.down["TABLET"]]: {
    flex: 1,
    flexBasis: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Content = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.section,
  textAlign: "left",
  width: "fit-content",
  backgroundColor: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    padding: theme.spacing.regular,
  },

  [theme.media.down["TABLET"]]: {
    padding: theme.spacing.middle,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head3,
  fontWeight: "bold",
  color: theme.color.black,
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    marginBottom: theme.spacing.xSmall,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
    marginBottom: theme.spacing.xSmall,
  },
}));

const Email = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  color: theme.color.gold,
  marginBottom: theme.spacing.section,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.default,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.middle,
  },
}));

const EmailButton = styled.a<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px ${theme.spacing.section}px`,
  color: theme.color.white,
  backgroundColor: "black",
  fontSize: theme.font.body3,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.small}px ${theme.spacing.default}px`,
    fontSize: theme.font.caption2,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.small}px ${theme.spacing.middle}px`,
    fontSize: theme.font.caption2,
  },
}));

export default HomeContactUs;
