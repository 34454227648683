import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import checkedIcon from "../../../assets/icons/checked.svg";
import uncheckedIcon from "../../../assets/icons/unchecked.svg";
import { OptionType } from "../../../lib/apollo/graphql/generated";
import { OPTION_TYPES } from "../../../utilities/constants";
import { ContactSectionTitle } from "../common/styles/contactCommonStyles";

interface ContactRadioSectionProps {
  type: string;
  options: ({
    __typename?: "Option" | undefined;
    id: string;
    type: OptionType;
    title: string;
    titleEng: string;
    active: boolean;
    sort: number;
    icon?:
      | { __typename?: "Media" | undefined; id: string; uri: string }
      | null
      | undefined;
  } | null)[];
  selectedOptionIds: string[];
  onOptionsChange: (id: string, type: string) => void;
}

function ContactRadioSection({
  type,
  options,
  selectedOptionIds,
  onOptionsChange,
}: ContactRadioSectionProps) {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  return (
    <Wrapper>
      <ContactSectionTitle>{t("contact." + type)}</ContactSectionTitle>
      <RadiosWrapper>
        {options.map((option) => (
          <RadioWrapper
            key={option?.id}
            onClick={() => onOptionsChange(option?.id!, OPTION_TYPES.FUNNEL)}
          >
            <Radio
              src={
                selectedOptionIds?.includes(option?.id!)
                  ? checkedIcon
                  : uncheckedIcon
              }
            />
            <div>
              {currentLanguage === "ko" ? option?.title : option?.titleEng}
            </div>
          </RadioWrapper>
        ))}
      </RadiosWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.default,
  color: theme.color.black,
  width: "100%",
}));

const RadiosWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "flex-start",
  gap: theme.spacing.middle,
  flexWrap: "wrap",
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.regular,
  },

  [theme.media.down["TABLET"]]: {
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    gap: theme.spacing.default,
  },
}));

const RadioWrapper = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.xSmall,
  fontSize: theme.font.body3,
  padding: 0,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
  },
}));

const Radio = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 20,
  height: 20,

  [theme.media.down["TABLET"]]: {
    width: 24,
    height: 24,
  },
}));

export default ContactRadioSection;
