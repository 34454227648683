import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import logo1 from "../../../assets/images/brand_1.webp";
import logo2 from "../../../assets/images/brand_2.svg";
import logo3 from "../../../assets/images/brand_3.svg";
import logo4 from "../../../assets/images/brand_4.svg";
import logo5 from "../../../assets/images/brand_5.svg";

const PARTNERS = [
  {
    logo: logo1,
    alt: "The innovators, 더이노베이터스",
  },
  {
    logo: logo2,
    alt: "Pepperlab, 페퍼랩, Pepperpost, 페퍼포스트",
  },
  {
    logo: logo3,
    alt: "BGA, Background art works, bga app",
  },
  {
    logo: logo4,
    alt: "Mermaid company, 머메이드 컴퍼니",
  },
  {
    logo: logo5,
    alt: "",
  },
];

function HomePartners() {
  return (
    <Wrapper>
      {PARTNERS.map((partner) => (
        <LogoWrapper key={partner.logo}>
          <Logo src={partner.logo} alt={partner.alt} />
        </LogoWrapper>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  maxWidth: theme.appMaxWidth,
  width: "100%",
  padding: `${theme.spacing.default}px ${theme.spacing.large}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: (theme.spacing.section * 3) / 2,

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    gap: theme.spacing.middle,
    flexWrap: "wrap",
  },
}));

const LogoWrapper = styled.div<any>(() => ({
  flex: 1,
}));

const Logo = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  height: "100%",
  maxHeight: 60,
  objectFit: "contain",

  [theme.media.down["DESKTOP"]]: {
    maxHeight: 40,
  },
}));

export default HomePartners;
