import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  PhoneNumber: any;
  Upload: any;
};

export type AllOption = {
  __typename?: 'AllOption';
  bmOptions: Array<Maybe<Option>>;
  funnelOptions: Array<Maybe<Option>>;
  productOptions: Array<Maybe<Option>>;
  purposeOptions: Array<Maybe<Option>>;
  readinessOptions: Array<Maybe<Option>>;
};

export type Contact = {
  __typename?: 'Contact';
  bmOptions?: Maybe<Array<Maybe<Option>>>;
  budget: Scalars['String'];
  businessField: Scalars['String'];
  contactedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  funnelOptions?: Maybe<Array<Maybe<Option>>>;
  id: Scalars['ID'];
  managerName: Scalars['String'];
  managerPosition: Scalars['String'];
  media?: Maybe<Media>;
  phone: Scalars['String'];
  productOptions?: Maybe<Array<Maybe<Option>>>;
  purposeOptions?: Maybe<Array<Maybe<Option>>>;
  readinessOptions?: Maybe<Array<Maybe<Option>>>;
  team: Scalars['String'];
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<Maybe<Contact>>;
  pageInfo: PageInfo;
};

export type ContactInput = {
  bmOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  budget: Scalars['String'];
  businessField: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  funnelOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  managerName: Scalars['String'];
  managerPosition: Scalars['String'];
  mediaInput?: InputMaybe<MediaInput>;
  phone: Scalars['String'];
  productOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  purposeOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  readinessOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  team: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type MediaInput = {
  file: Scalars['Upload'];
  thumbnailFile?: InputMaybe<Scalars['Upload']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Boolean']>;
  createContact: Contact;
  deleteOptionAsAdmin: Scalars['Boolean'];
  updateContactContactedAtAsAdmin: Contact;
  updateOptionAsAdmin: Option;
  updatePrivacyPolicyAsAdmin: PrivacyPolicy;
};


export type MutationCreateContactArgs = {
  contactInput: ContactInput;
};


export type MutationDeleteOptionAsAdminArgs = {
  optionId: Scalars['ID'];
  type: OptionType;
};


export type MutationUpdateContactContactedAtAsAdminArgs = {
  contactId: Scalars['ID'];
  contactedAt: Scalars['DateTime'];
};


export type MutationUpdateOptionAsAdminArgs = {
  optionInput: OptionInput;
};


export type MutationUpdatePrivacyPolicyAsAdminArgs = {
  privacyPolicyInput: PrivacyPolicyInput;
};

export type Option = {
  __typename?: 'Option';
  active: Scalars['Boolean'];
  icon?: Maybe<Media>;
  id: Scalars['ID'];
  sort: Scalars['Int'];
  title: Scalars['String'];
  titleEng: Scalars['String'];
  type: OptionType;
};

export type OptionInput = {
  active: Scalars['Boolean'];
  iconInput?: InputMaybe<MediaInput>;
  id?: InputMaybe<Scalars['ID']>;
  sort: Scalars['Int'];
  title: Scalars['String'];
  titleEng: Scalars['String'];
  type: OptionType;
};

export enum OptionType {
  Bm = 'BM',
  Funnel = 'FUNNEL',
  Product = 'PRODUCT',
  Purpose = 'PURPOSE',
  Readiness = 'READINESS'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  policy: Scalars['String'];
};

export type PrivacyPolicyInput = {
  id?: InputMaybe<Scalars['ID']>;
  policy: Scalars['String'];
};

export type PrivacyPolicyVersion = {
  __typename?: 'PrivacyPolicyVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Boolean']>;
  getAllOptions: AllOption;
  getContactAsAdmin: Contact;
  getContactsAsAdmin: ContactConnection;
  getLatestPrivacyPolicy: PrivacyPolicy;
  getOptionTypes: Array<Maybe<OptionType>>;
  getPrivacyPolicy: PrivacyPolicy;
  getPrivacyPolicyVersions: Array<Maybe<PrivacyPolicyVersion>>;
  searchContactsAsAdmin: ContactConnection;
};


export type QueryGetAllOptionsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetContactAsAdminArgs = {
  contactId: Scalars['ID'];
};


export type QueryGetContactsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPrivacyPolicyArgs = {
  policyId: Scalars['ID'];
};


export type QuerySearchContactsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type OptionFieldFragment = { __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null };

export type PrivacyPolicyFieldFragment = { __typename?: 'PrivacyPolicy', id: string, policy: string, createdAt: any };

export type GetAllOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOptionsQuery = { __typename?: 'Query', getAllOptions: { __typename?: 'AllOption', readinessOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, productOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, purposeOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, bmOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, funnelOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> } };

export type GetOptionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOptionTypesQuery = { __typename?: 'Query', getOptionTypes: Array<OptionType | null> };

export type CreateContactMutationVariables = Exact<{
  contactInput: ContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'Contact', id: string, team: string, managerName: string, email: string, phone: string } };

export type GetLatestPrivacyPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestPrivacyPolicyQuery = { __typename?: 'Query', getLatestPrivacyPolicy: { __typename?: 'PrivacyPolicy', id: string, policy: string, createdAt: any } };

export const OptionFieldFragmentDoc = gql`
    fragment OptionField on Option {
  id
  type
  title
  titleEng
  active
  sort
  icon {
    id
    uri
  }
}
    `;
export const PrivacyPolicyFieldFragmentDoc = gql`
    fragment PrivacyPolicyField on PrivacyPolicy {
  id
  policy
  createdAt
}
    `;
export const GetAllOptionsDocument = gql`
    query GetAllOptions {
  getAllOptions {
    readinessOptions {
      ...OptionField
    }
    productOptions {
      ...OptionField
    }
    purposeOptions {
      ...OptionField
    }
    bmOptions {
      ...OptionField
    }
    funnelOptions {
      ...OptionField
    }
  }
}
    ${OptionFieldFragmentDoc}`;

/**
 * __useGetAllOptionsQuery__
 *
 * To run a query within a React component, call `useGetAllOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOptionsQuery, GetAllOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOptionsQuery, GetAllOptionsQueryVariables>(GetAllOptionsDocument, options);
      }
export function useGetAllOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOptionsQuery, GetAllOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOptionsQuery, GetAllOptionsQueryVariables>(GetAllOptionsDocument, options);
        }
export type GetAllOptionsQueryHookResult = ReturnType<typeof useGetAllOptionsQuery>;
export type GetAllOptionsLazyQueryHookResult = ReturnType<typeof useGetAllOptionsLazyQuery>;
export type GetAllOptionsQueryResult = Apollo.QueryResult<GetAllOptionsQuery, GetAllOptionsQueryVariables>;
export const GetOptionTypesDocument = gql`
    query GetOptionTypes {
  getOptionTypes
}
    `;

/**
 * __useGetOptionTypesQuery__
 *
 * To run a query within a React component, call `useGetOptionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOptionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetOptionTypesQuery, GetOptionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionTypesQuery, GetOptionTypesQueryVariables>(GetOptionTypesDocument, options);
      }
export function useGetOptionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionTypesQuery, GetOptionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionTypesQuery, GetOptionTypesQueryVariables>(GetOptionTypesDocument, options);
        }
export type GetOptionTypesQueryHookResult = ReturnType<typeof useGetOptionTypesQuery>;
export type GetOptionTypesLazyQueryHookResult = ReturnType<typeof useGetOptionTypesLazyQuery>;
export type GetOptionTypesQueryResult = Apollo.QueryResult<GetOptionTypesQuery, GetOptionTypesQueryVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($contactInput: ContactInput!) {
  createContact(contactInput: $contactInput) {
    id
    team
    managerName
    email
    phone
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const GetLatestPrivacyPolicyDocument = gql`
    query GetLatestPrivacyPolicy {
  getLatestPrivacyPolicy {
    id
    policy
    createdAt
  }
}
    `;

/**
 * __useGetLatestPrivacyPolicyQuery__
 *
 * To run a query within a React component, call `useGetLatestPrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestPrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestPrivacyPolicyQuery, GetLatestPrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestPrivacyPolicyQuery, GetLatestPrivacyPolicyQueryVariables>(GetLatestPrivacyPolicyDocument, options);
      }
export function useGetLatestPrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestPrivacyPolicyQuery, GetLatestPrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestPrivacyPolicyQuery, GetLatestPrivacyPolicyQueryVariables>(GetLatestPrivacyPolicyDocument, options);
        }
export type GetLatestPrivacyPolicyQueryHookResult = ReturnType<typeof useGetLatestPrivacyPolicyQuery>;
export type GetLatestPrivacyPolicyLazyQueryHookResult = ReturnType<typeof useGetLatestPrivacyPolicyLazyQuery>;
export type GetLatestPrivacyPolicyQueryResult = Apollo.QueryResult<GetLatestPrivacyPolicyQuery, GetLatestPrivacyPolicyQueryVariables>;