import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled, { ThemedComponentProps } from "styled-components";
import backgroundImageMobile from "../../../assets/images/home_intro_image_mobile.webp";
import backgroundImageTablet from "../../../assets/images/home_intro_image_tablet.webp";
import backgroundImage from "../../../assets/images/home_intro_image.webp";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

interface HomeIntroProps {
  introRef: (node?: Element | null | undefined) => void;
}

function HomeIntro({ introRef }: HomeIntroProps) {
  const { t } = useTranslation();
  const { screenWidth, isPc, isTablet, isMobile } = useScreenWidthContext();

  const introSectionRatio = isPc ? 0.42 : 0.6;

  return (
    <Wrapper
      ref={introRef}
      style={{
        height: isMobile ? "fit-content" : screenWidth * introSectionRatio,
      }}
    >
      {!isMobile ? (
        <div>
          <Title>
            <TitleGradient1>
              {t("home.introTitle1")}
              <GradientUnderline />
            </TitleGradient1>
            <div>{t("home.introTitle2")}</div>
          </Title>
          <Title>
            <TitleGradient2>
              {t("home.introTitle3")}
              <GradientUnderline />
            </TitleGradient2>
            <div>{t("home.introTitle4")}</div>
          </Title>
        </div>
      ) : (
        <div>
          <Title>
            <TitleGradient1>
              {t("home.introTitleMobile1")}
              <GradientUnderline />
            </TitleGradient1>
          </Title>
          <Title>
            <TitleGradient1>
              {t("home.introTitleMobile2")}
              <GradientUnderline />
            </TitleGradient1>
            <div>{t("home.introTitle2")}</div>
          </Title>
          <Title>
            <TitleGradient2>
              {t("home.introTitle3")}
              <GradientUnderline />
            </TitleGradient2>
            <div>{t("home.introTitle4")}</div>
          </Title>
        </div>
      )}

      <SliderWrapper>
        <Slider
          dots={false}
          infinite
          slidesToShow={1}
          slidesToScroll={1}
          vertical
          verticalSwiping
          arrows={false}
          autoplay
          autoplaySpeed={1500}
        >
          <div>{t("home.introOption1")}</div>
          <div>{t("home.introOption2")}</div>
          <div>{t("home.introOption3")}</div>
          <div>{t("home.introOption4")}</div>
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing.section,

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.middle,
    backgroundImage: `url(${backgroundImageTablet})`,
  },

  [theme.media.down["TABLET"]]: {
    backgroundImage: `url(${backgroundImageMobile})`,
    alignItems: "flex-start",
    padding: `${theme.spacing.section * 2}px ${theme.spacing.default}px`,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: 51,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.head3,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body1,
    justifyContent: "flex-start",
  },
}));

const TitleGradient1 = styled.div<any>(() => ({
  position: "relative",
  background:
    "linear-gradient(to right, #d6daff -3%, #ffc773 55%, #fb7a91 105%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
}));

const GradientUnderline = styled.div<any>(() => ({
  bottom: 0,
  left: 0,
  right: 0,
  height: 1,
  background: "linear-gradient(to right, #ffedd2 0%, #f2ffbe 100%)",
}));

const TitleGradient2 = styled.div<any>(() => ({
  position: "relative",
  background: "linear-gradient(92deg, #bff5db -5%, #ffec8a 64%, #ffa621 119%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
}));

const SliderWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.middle}px 0px`,
  maxWidth: 396,
  width: "100%",
  border: `3px solid ${theme.color.white}`,
  fontSize: theme.font.body1,
  fontWeight: "bold",
  backgroundColor: "rgba(255,255,255,0.2)",

  [theme.media.down["DESKTOP"]]: {
    maxWidth: 224,
    padding: `${theme.spacing.normal}px 0px`,
    fontSize: theme.font.body3,
    borderWidth: 1.3,
  },

  [theme.media.down["TABLET"]]: {
    maxWidth: 168,
    padding: `${theme.spacing.small}px 0px`,
    fontSize: theme.font.caption1,
    borderWidth: 1.2,
  },
}));

export default HomeIntro;
