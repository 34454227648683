import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import logo from "../../../assets/images/footsketch_logo.svg";
import { PATH_NAMES } from "../../../lib/router/utils/router";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface NavigationBarProps {
  introInView: boolean;
  isHome?: boolean;
  serviceSummaryRef?: React.RefObject<HTMLDivElement>;
  projectsRef?: React.RefObject<HTMLDivElement>;
}

function NavigationBar({
  introInView,
  isHome = true,
  serviceSummaryRef,
  projectsRef,
}: NavigationBarProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Wrapper
      style={{
        position: introInView ? "absolute" : "fixed",
        backgroundColor: introInView ? "transparent" : "black",
      }}
    >
      <NavWrapper>
        <div>
          <Link to={PATH_NAMES.HOME.path}>
            <Logo alt="Footsketch, 풋스케치, MVP 개발, 앱 개발, 웹 개발, 서비스 기획, 기획 자문, 개발 교육" />
          </Link>
        </div>
        {isHome && !isMobile && (
          <OptionsWrapper>
            <Option
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              {t("home.navOption1")}
            </Option>
            <Option
              onClick={() =>
                serviceSummaryRef?.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {t("home.navOption2")}
            </Option>
            <Option
              onClick={() =>
                projectsRef?.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {t("home.navOption3")}
            </Option>
          </OptionsWrapper>
        )}

        <Link to={PATH_NAMES.CONTACT.path}>
          <Contact>{t("home.navContact")}</Contact>
        </Link>
      </NavWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  left: 0,
  right: 0,
  top: 0,
  zIndex: 99,
}));

const NavWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  margin: "0px auto",
  backgroundColor: "transparent",
  width: "100%",
  maxWidth: theme.appMaxWidth,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `${theme.spacing.default}px ${theme.spacing.large}px`,

  height: theme.desktopNavHeight,

  [theme.media.down["DESKTOP"]]: {
    height: theme.tabletNavHeight,
  },

  [theme.media.down["TABLET"]]: {
    padding: theme.spacing.default,
    height: theme.mobileNavHeight,
  },
}));

const Logo = styled.img.attrs(() => ({
  src: logo,
}))<any>(({ theme }: ThemedComponentProps) => ({
  height: 36,
  objectFit: "contain",

  [theme.media.down["DESKTOP"]]: {
    height: 32,
  },
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.section,
}));

const Option = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
  fontSize: theme.font.body3,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

const Contact = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.gold,
  padding: `${theme.spacing.small}px ${theme.spacing.section}px`,
  color: theme.color.black,
  fontSize: theme.font.body3,
  fontWeight: "bold",

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.xSmall}px ${theme.spacing.regular}px`,
    fontWeight: "normal",
    fontSize: theme.font.body3,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
    fontSize: theme.font.caption1,
  },
}));

export default NavigationBar;
