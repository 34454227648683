import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled, { ThemedComponentProps } from "styled-components";
import arrowLeftIcon from "../../../assets/icons/project_arrow_btn_left.svg";
import bgaImage from "../../../assets/images/project_bga.webp";
import menninImage from "../../../assets/images/project_mennin.webp";
import msImage from "../../../assets/images/project_ms.webp";
import pepperImage from "../../../assets/images/project_pepper.webp";
import sharexpereImage from "../../../assets/images/project_sharexpere.webp";
import ttakImage from "../../../assets/images/project_ttak.webp";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

const PROJECTS = [
  {
    image: sharexpereImage,
    os: "iOS / Android",
    alt: "셰어익스피어, sharexpere, 입시 멘토링",
    text: "home.projectSharexpere",
  },
  {
    image: bgaImage,
    os: "Web / iOS / Android",
    alt: "BGA, Background art works, Background artworks, 비지에이, 백그라운드 아트웍스",
    text: "home.projectBga",
  },
  {
    image: menninImage,
    os: "Web",
    alt: "Mennin, NFT, ERC-721, Smart contract, 스마트 컨트랙트, 마켓플레이스, NFT marketplace",
    text: "home.projectMennin",
  },
  {
    image: msImage,
    os: "Web / Chrome Extension",
    alt: "million seller, 스마트스토어, 스마트스토어 분석, 판매자 분석, 상품 분석, 네이버, 네이버 스마트스토어",
    text: "home.projectMs",
  },
  {
    image: pepperImage,
    os: "iOS / Android",
    alt: "친환경 식자재, 친환경 식품, 페퍼랩, 페퍼포스트, pepper post, 유기농",
    text: "home.projectPepper",
  },
  {
    image: ttakImage,
    os: "iOS / Android",
    alt: "단기 일자리, 아르바이트, 구인, 구직, 채용, 딱, TTAK, 머메이드 컴퍼니",
    text: "home.projectTtak",
  },
];

interface HomeProjectsProps {
  projectsRef?: React.RefObject<HTMLDivElement>;
}

function HomeProjects({ projectsRef }: HomeProjectsProps) {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider>(null);

  const { isTablet, isMobile } = useScreenWidthContext();

  return (
    <Wrapper ref={projectsRef}>
      <TitleWrapper>
        <Project>Project</Project>
        <Title>{t("home.projectTitle")}</Title>
        <Subtitle>{t("home.projectSubtitle")}</Subtitle>
      </TitleWrapper>

      <SliderWrapper>
        <Slider
          ref={sliderRef}
          dots={false}
          slidesToShow={isMobile ? 1 : isTablet ? 2 : 3}
          centerMode
          infinite
          arrows={false}
          autoplay
          autoplaySpeed={2500}
          speed={500}
          pauseOnHover
        >
          {PROJECTS.map((project) => (
            <Item key={project.text}>
              <ItemImage src={project.image} alt={project.alt} />
              <ItemOS>{project.os}</ItemOS>
              <ItemText>{t(project.text)}</ItemText>
            </Item>
          ))}
        </Slider>
        {!isMobile && (
          <>
            <PrevArrow onClick={() => sliderRef?.current?.slickPrev()}>
              <PrevIcon />
            </PrevArrow>
            <NextArrow onClick={() => sliderRef?.current?.slickNext()}>
              <NextIcon />
            </NextArrow>
          </>
        )}
      </SliderWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  paddingTop: theme.spacing.section * 2,
  paddingBottom: theme.spacing.section * 3,

  [theme.media.down["DESKTOP"]]: {
    paddingTop: theme.spacing.section,
    paddingBottom: (theme.spacing.section * 3) / 2,
  },

  [theme.media.down["TABLET"]]: {
    paddingTop: theme.spacing.section,
    paddingBottom: theme.spacing.section,
  },
}));

const TitleWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.appMaxWidth,
  paddingLeft: theme.spacing.large + theme.spacing.section,
  textAlign: "left",
  marginBottom: theme.spacing.section * 2,

  [theme.media.down["DESKTOP"]]: {
    paddingLeft: theme.spacing.large + theme.spacing.regular,
    marginBottom: theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    paddingLeft: theme.spacing.default,
  },
}));

const Project = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontFamily: "Playfair Display",
  fontSize: 37,
  color: theme.color.black,
  WebkitTextStroke: `1px ${theme.color.gray1}`,
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: 28,
    marginBottom: theme.spacing.small,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: 16,
    marginBottom: 0,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head1,
  marginBottom: theme.spacing.xSmall,
  fontWeight: "bold",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.head2,
    marginBottom: theme.spacing.xSmall,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body2,
    marginBottom: 0,
  },
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.caption1,
  },
}));

const SliderWrapper = styled.div<any>(() => ({
  position: "relative",
  width: "100%",
}));

const Item = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  textAlign: "left",
  padding: `0px ${theme.spacing.regular}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `0px ${theme.spacing.regular / 2}px`,
  },

  [theme.media.down["DESKTOP"]]: {
    padding: `0px ${theme.spacing.small}px`,
  },
}));

const ItemImage = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  objectFit: "contain",
  marginBottom: theme.spacing.middle,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.normal,
  },
}));

const ItemOS = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  color: theme.color.gray1,
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption2,
    marginBottom: theme.spacing.xSmall,
  },
}));

const ItemText = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  color: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

const PrevArrow = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  left: `calc((100vh - ${theme.appMaxWidth / 2}px) / 2 - (${
    theme.spacing.large
  }px + ${theme.spacing.section}px) / 2)`,
  top: "30%",
  padding: 0,

  [theme.media.down["DESKTOP"]]: {
    left: theme.spacing.large + theme.spacing.regular,
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

const PrevIcon = styled.img.attrs(() => ({
  src: arrowLeftIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 80,
  height: 80,

  [theme.media.down["DESKTOP"]]: {
    width: 48,
    height: 48,
  },
}));

const NextArrow = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  right: `calc((100vh - ${theme.appMaxWidth / 2}px) / 2 - (${
    theme.spacing.large
  }px + ${theme.spacing.section}px) / 2)`,
  top: "30%",
  padding: 0,

  [theme.media.down["DESKTOP"]]: {
    right: theme.spacing.large + theme.spacing.regular,
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

const NextIcon = styled.img.attrs(() => ({
  src: arrowLeftIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  transform: "rotate(180deg)",
  width: 80,
  height: 80,

  [theme.media.down["DESKTOP"]]: {
    width: 48,
    height: 48,
  },
}));

export default HomeProjects;
