import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import arrowIcon from "../../../assets/icons/chevron_right.svg";
import sharexpereImageMobile from "../../../assets/images/sharexpere_mobile.webp";
import sharexpereImage from "../../../assets/images/sharexpere.webp";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

function HomeSharexpere() {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{t("home.sharexpereTitle")}</Title>
        <Description>{t("home.sharexpereDescription")}</Description>
        <Image src={isMobile ? sharexpereImageMobile : sharexpereImage} />
        <Button onClick={() => window.open("https://sharexpere.com", "_blank")}>
          <div>{t("home.sharexpereWebButton")}</div>
          <ButtonIcon />
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.appMaxWidth,

  [theme.media.down["DESKTOP"]]: {},

  [theme.media.down["TABLET"]]: {},
}));

const ContentWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  margin: "0px auto",
  width: "80%",
  paddingTop: theme.spacing.section * 3,
  paddingBottom: theme.spacing.section * 2,
  color: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    paddingTop: (theme.spacing.section * 3) / 2,
    paddingBottom: theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    width: "100%",
    paddingTop: theme.spacing.section,
    paddingBottom: theme.spacing.section,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head1,
  fontWeight: "bold",
  marginBottom: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.head3,
    marginBottom: theme.spacing.small,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body2,
  },
}));

const Description = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  color: theme.color.gray1,
  marginBottom: theme.spacing.section,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.middle,
  },
}));

const Image = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  objectFit: "contain",
  marginBottom: theme.spacing.section,

  [theme.media.down["TABLET"]]: {
    marginBottom: theme.spacing.regular,
  },
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing.normal}px ${theme.spacing.middle}px`,
  gap: theme.spacing.small,
  fontSize: theme.font.body3,
  margin: "0px auto",
  border: `1px solid ${theme.color.white}`,
  lineHeight: 1,

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.caption1,
  },
}));

const ButtonIcon = styled.img.attrs(() => ({
  src: arrowIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 24,
  height: 24,
}));

export default HomeSharexpere;
