import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_NAMES } from "../../../lib/router/utils/router";
import { BUSINESS_FIELDS, OPTION_TYPES } from "../../../utilities/constants";
import { validateEmail } from "../../common/utils/validateEmail";
import {
  useCreateContactMutation,
  useGetAllOptionsQuery,
} from "../../../lib/apollo/graphql/generated";

function useContactContainer() {
  const navigate = useNavigate();

  const { loading, data } = useGetAllOptionsQuery();

  const [createContact, { loading: createLoading }] = useCreateContactMutation({
    onCompleted: (data) => {
      if (data?.createContact?.id) {
        navigate(PATH_NAMES.CONTACTED.path, {
          state: {
            name: data?.createContact?.managerName,
          },
        });
        setState({
          team: "",
          businessField: "",
          managerName: "",
          managerPosition: "",
          phone: "",
          email: "",
          emailError: false,
          budget: "",
          description: "",
          mediaState: null,
          readinessOptionIds: [""],
          productOptionIds: [""],
          purposeOptionIds: [""],
          bmOptionIds: [""],
          funnelOptionIds: [""],
          agreed: false,
        });
      }
    },
  });

  const [state, setState] = useState(() => ({
    team: "",
    businessField: "",
    managerName: "",
    managerPosition: "",
    phone: "",
    email: "",
    emailError: false,
    budget: "",
    description: "",
    mediaState: null,
    readinessOptionIds: [""],
    productOptionIds: [""],
    purposeOptionIds: [""],
    bmOptionIds: [""],
    funnelOptionIds: [""],
    agreed: false,
  }));

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value.trim(),
    }));
  }

  function onTextareaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      description: value,
    }));
  }

  function onBusinessFieldChange(id: string) {
    setState((prev) => ({
      ...prev,
      businessField: id,
    }));
  }

  function onOptionsChange(id: string, type: string) {
    switch (type) {
      case OPTION_TYPES.PRODUCT: {
        const { productOptionIds } = state;
        let editedProductOptionIds = [...productOptionIds];

        if (editedProductOptionIds.includes(id)) {
          editedProductOptionIds = editedProductOptionIds.filter(
            (optionId) => optionId !== id
          );
        } else {
          editedProductOptionIds.push(id);
        }

        setState((prev) => ({
          ...prev,
          productOptionIds: editedProductOptionIds,
        }));

        break;
      }

      case OPTION_TYPES.READINESS: {
        let editedReadinessOptionIds = [id];

        setState((prev) => ({
          ...prev,
          readinessOptionIds: editedReadinessOptionIds,
        }));

        break;
      }

      case OPTION_TYPES.PURPOSE: {
        const { purposeOptionIds } = state;
        let editedPurposeOptionIds = [...purposeOptionIds];

        if (editedPurposeOptionIds.includes(id)) {
          editedPurposeOptionIds = editedPurposeOptionIds.filter(
            (optionId) => optionId !== id
          );
        } else {
          editedPurposeOptionIds.push(id);
        }

        setState((prev) => ({
          ...prev,
          purposeOptionIds: editedPurposeOptionIds,
        }));

        break;
      }

      case OPTION_TYPES.BM: {
        const { bmOptionIds } = state;
        let editedBmOptionIds = [...bmOptionIds];

        if (editedBmOptionIds.includes(id)) {
          editedBmOptionIds = editedBmOptionIds.filter(
            (optionId) => optionId !== id
          );
        } else {
          editedBmOptionIds.push(id);
        }

        setState((prev) => ({
          ...prev,
          bmOptionIds: editedBmOptionIds,
        }));

        break;
      }

      case OPTION_TYPES.FUNNEL: {
        let editedFunnelOptionIds = [id];

        setState((prev) => ({
          ...prev,
          funnelOptionIds: editedFunnelOptionIds,
        }));

        break;
      }

      default: {
        break;
      }
    }
  }

  function onFileChange(file: any) {
    setState((prev) => ({
      ...prev,
      mediaState: file,
    }));
  }

  function onEmailFocus() {
    const { emailError } = state;

    if (emailError) {
      setState((prev) => ({
        ...prev,
        emailError: false,
      }));
    }
  }

  function onEmailBlur() {
    const { email } = state;

    const validated = validateEmail(email);

    if (!validated) {
      setState((prev) => ({
        ...prev,
        emailError: true,
      }));
    }
  }

  function toggleAgreed() {
    setState((prev) => ({
      ...prev,
      agreed: !state.agreed,
    }));
  }

  function isSubmitDisabled() {
    const {
      budget,
      team,
      managerName,
      managerPosition,
      phone,
      email,
      emailError,
      businessField,
      productOptionIds,
      readinessOptionIds,
      bmOptionIds,
      purposeOptionIds,
      funnelOptionIds,
      agreed,
    } = state;

    const isInputsFilled =
      !!budget &&
      !!team &&
      !!managerName &&
      !!managerPosition &&
      !!phone &&
      !!email &&
      !!businessField;

    const noError = !emailError;

    const areOptionsFilled =
      productOptionIds?.length > 0 &&
      readinessOptionIds?.length > 0 &&
      bmOptionIds?.length > 0 &&
      purposeOptionIds?.length > 0 &&
      funnelOptionIds?.length > 0;

    return !(isInputsFilled && noError && areOptionsFilled && agreed);
  }

  function onSubmit() {
    const {
      budget,
      team,
      managerName,
      managerPosition,
      phone,
      email,
      businessField,
      productOptionIds,
      readinessOptionIds,
      bmOptionIds,
      purposeOptionIds,
      funnelOptionIds,
      mediaState,
    } = state;

    const field = BUSINESS_FIELDS.find((value) => value.id === businessField);

    createContact({
      variables: {
        contactInput: {
          budget,
          team,
          managerName,
          managerPosition,
          phone,
          email,
          businessField: field!.ko + "/" + field!.en,
          productOptionIds: productOptionIds.filter((optionId) => !!optionId),
          readinessOptionIds: readinessOptionIds.filter(
            (optionId) => !!optionId
          ),
          bmOptionIds: bmOptionIds.filter((optionId) => !!optionId),
          purposeOptionIds: purposeOptionIds.filter((optionId) => !!optionId),
          funnelOptionIds: funnelOptionIds.filter((optionId) => !!optionId),
          mediaInput: mediaState ? { file: mediaState } : null,
        },
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getAllOptions,
      state,
      createLoading,
    },
    operations: {
      onInputChange,
      onTextareaChange,
      onBusinessFieldChange,
      onOptionsChange,
      onFileChange,
      onEmailFocus,
      onEmailBlur,
      toggleAgreed,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useContactContainer;
