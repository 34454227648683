import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import ContactOption from "./ContactOption";
import { OptionType } from "../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import { ContactSectionTitle } from "../common/styles/contactCommonStyles";

interface ContactOptionsSectionProps {
  type: string;
  options: ({
    __typename?: "Option" | undefined;
    id: string;
    type: OptionType;
    title: string;
    titleEng: string;
    active: boolean;
    sort: number;
    icon?:
      | { __typename?: "Media" | undefined; id: string; uri: string }
      | null
      | undefined;
  } | null)[];
  selectedOptionIds: string[];
  isDuplicable?: boolean;
  onOptionsChange: (id: string, type: string) => void;
}

function ContactOptionsSection({
  type,
  options,
  selectedOptionIds,
  isDuplicable = true,
  onOptionsChange,
}: ContactOptionsSectionProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Wrapper>
      <ContactSectionTitle>
        {t("contact." + type)} {isMobile && <br />}
        {isDuplicable && <Caption>{t("contact.duplicatableCaption")}</Caption>}
      </ContactSectionTitle>
      <OptionsWrapper>
        {options.map((option) => (
          <ContactOption
            key={option?.id}
            option={option}
            selected={selectedOptionIds.includes(option?.id!)}
            onClick={() => onOptionsChange(option?.id!, type)}
          />
        ))}
      </OptionsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.default,
  color: theme.color.black,
  width: "100%",
}));

const Caption = styled.span<any>(({ theme }: ThemedComponentProps) => ({
  marginLeft: theme.spacing.default,
  fontSize: theme.font.caption1,
  fontWeight: "normal",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption2,
  },

  [theme.media.down["TABLET"]]: {
    marginLeft: 0,
  },
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "flex-start",
  gap: theme.spacing.default,
  flexWrap: "wrap",
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.small,
  },

  [theme.media.down["TABLET"]]: {
    flexWrap: "nowrap",
    flexDirection: "column",
  },
}));

export default ContactOptionsSection;
