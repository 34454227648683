import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Footer from "../../common/components/Footer";
import NavigationBar from "../../common/components/NavigationBar";
import HomeBreak from "../components/HomeBreak";
import HomeContactUs from "../components/HomeContactUs";
import HomeIntro from "../components/HomeIntro";
import HomePartners from "../components/HomePartners";
import HomeProjects from "../components/HomeProjects";
import HomeService from "../components/HomeService";
import HomeServiceSummary from "../components/HomeServiceSummary";
import HomeSharexpere from "../components/HomeSharexpere";
import HomeSummary from "../components/HomeSummary";

function HomeContainer() {
  const { ref: introRef, inView: introInView } = useInView();
  const service1Ref = useRef<HTMLDivElement>(null);
  const service2Ref = useRef<HTMLDivElement>(null);
  const service3Ref = useRef<HTMLDivElement>(null);
  const service4Ref = useRef<HTMLDivElement>(null);

  const serviceSummaryRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper>
      <NavigationBar
        introInView={introInView}
        serviceSummaryRef={serviceSummaryRef}
        projectsRef={projectsRef}
      />
      <HomeIntro introRef={introRef} />
      <HomePartners />
      <HomeSummary />
      <HomeServiceSummary
        serviceSummaryRef={serviceSummaryRef}
        service1Ref={service1Ref}
        service2Ref={service2Ref}
        service3Ref={service3Ref}
        service4Ref={service4Ref}
      />
      <HomeService
        service1Ref={service1Ref}
        service2Ref={service2Ref}
        service3Ref={service3Ref}
        service4Ref={service4Ref}
      />
      <HomeBreak />
      <HomeSharexpere />
      <HomeProjects projectsRef={projectsRef} />
      <HomeContactUs />
      <Footer serviceSummaryRef={serviceSummaryRef} projectsRef={projectsRef} />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export default HomeContainer;
