import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import summaryIcon from "../../../assets/icons/home_summary.svg";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import { HomeSubtitle, HomeTitle } from "../common/styles/homeCommonStyles";

const SUMMARIES = [
  {
    keyword: "Accountability",
    text: "home.summary1",
  },
  {
    keyword: "Reliability",
    text: "home.summary2",
  },
  {
    keyword: "Growth",
    text: "home.summary3",
  },
];

function HomeSummary() {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Wrapper>
      <HomeSubtitle>{t("home.summarySubtitle")}</HomeSubtitle>
      <HomeTitle>
        <div>
          {t("home.summaryTitle1")}
          {!isMobile && " " + t("home.summaryTitle2")}
        </div>
        {isMobile && <div>{t("home.summaryTitle2")}</div>}
        <div>{t("home.summaryTitle3")}</div>
      </HomeTitle>
      <SummaryWrapper>
        {SUMMARIES.map((summary) => (
          <Summary key={summary.text}>
            <SummaryLeft>
              <SummaryIcon src={summaryIcon} />
              <Keyword>{summary.keyword}</Keyword>
            </SummaryLeft>
            <Text>{t(summary.text)}</Text>
          </Summary>
        ))}
      </SummaryWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  maxWidth: theme.appMaxWidth,
  width: "100%",
  padding: `${theme.spacing.section * 3}px ${theme.spacing.large}px`,
  paddingTop: theme.spacing.section * 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  [theme.media.down["DESKTOP"]]: {
    padding: `${(theme.spacing.section * 3) / 2}px ${theme.spacing.large}px`,
    paddingTop: theme.spacing.section * 2,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.default}px`,
    paddingTop: (theme.spacing.section * 3) / 2,
  },
}));

const SummaryWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  borderTop: `0.3px solid ${theme.color.goldText}`,
  color: theme.color.goldText,
}));

const Summary = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  borderBottom: `0.3px solid ${theme.color.goldText}`,
  padding: `${theme.spacing.section}px ${theme.spacing.middle}px`,
  gap: (theme.spacing.section * 3) / 2,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.regular}px ${theme.spacing.normal}px`,
    gap: theme.spacing.middle,
  },

  [theme.media.down["TABLET"]]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing.xSmall,
    padding: theme.spacing.normal,
  },
}));

const SummaryLeft = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.regular,
  flex: 1,

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.small,
  },
}));

const SummaryIcon = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  height: 48,

  [theme.media.down["DESKTOP"]]: {
    height: 24,
  },

  [theme.media.down["TABLET"]]: {
    height: 20,
  },
}));

const Keyword = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: 42,
  fontWeight: "bold",
  fontFamily: "Playfair Display",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

const Text = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  flex: 2,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    flex: 4,
  },
}));

export default HomeSummary;
