import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import checkedIcon from "../../../assets/icons/checked.svg";
import uncheckedIcon from "../../../assets/icons/unchecked.svg";
import { BUSINESS_FIELDS } from "../../../utilities/constants";
import { ContactSectionTitle } from "../common/styles/contactCommonStyles";

interface ContactBusinessFieldSectionProps {
  selectedField: string;
  onChange: (id: string) => void;
}

function ContactBusinessFieldSection({
  selectedField,
  onChange,
}: ContactBusinessFieldSectionProps) {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  return (
    <Wrapper>
      <ContactSectionTitle>{t("contact.fieldLabel")}</ContactSectionTitle>
      <RadiosWrapper>
        {BUSINESS_FIELDS.map((field) => (
          <RadioWrapper key={field.id} onClick={() => onChange(field.id)}>
            <Radio
              src={selectedField === field.id ? checkedIcon : uncheckedIcon}
            />
            <div>{currentLanguage === "ko" ? field.ko : field.en}</div>
          </RadioWrapper>
        ))}
      </RadiosWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.default,
  color: theme.color.black,
  width: "100%",
}));

const RadiosWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "flex-start",
  gap: theme.spacing.middle,
  flexWrap: "wrap",
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.regular,
  },

  [theme.media.down["TABLET"]]: {
    gap: theme.spacing.default,
  },
}));

const RadioWrapper = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.xSmall,
  fontSize: theme.font.body3,
  padding: 0,
  flexBasis: "31%",
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
    flexBasis: "43%",
  },
}));

const Radio = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 20,
  height: 20,

  [theme.media.down["TABLET"]]: {
    width: 24,
    height: 24,
  },
}));

export default ContactBusinessFieldSection;
