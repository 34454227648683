import { ApolloProvider } from "@apollo/client";
import React from "react";
import { withTranslation } from "react-i18next";
import client from "./lib/apollo";
import RouterContainer from "./lib/router/container/RouterContainer";

function App() {
  return (
    <ApolloProvider client={client}>
      <RouterContainer />
    </ApolloProvider>
  );
}

export default withTranslation()(App);
