import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { ContactSectionTitle } from "../common/styles/contactCommonStyles";

interface ContactInputProps {
  label: string;
  placeholder: string;
  value: string;
  pattern?: string;
  error?: boolean;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

function ContactInput({
  label,
  placeholder,
  value,
  pattern,
  error = false,
  type,
  onChange,
  onFocus,
  onBlur,
}: ContactInputProps) {
  return (
    <Wrapper>
      <ContactSectionTitle>{label}</ContactSectionTitle>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        error={error}
        pattern={pattern}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.default,
  width: "100%",
}));

interface InputProps extends ThemedComponentProps {
  error?: boolean;
}

const Input = styled.input<any>(({ theme, error }: InputProps) => ({
  width: "100%",
  backgroundColor: error ? "rgba(248,76,76,0.1)" : "rgba(196,196,196,0.1)",
  borderBottom: `1px solid ${error ? theme.color.error : theme.color.black}`,
  padding: theme.spacing.default,
  color: theme.color.black,
  fontSize: theme.font.body3,

  "::placeholder": {
    color: theme.color.gray1,
  },

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
  },
}));

export default ContactInput;
