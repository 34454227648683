export const OPTION_TYPES = {
  READINESS: "READINESS",
  PRODUCT: "PRODUCT",
  PURPOSE: "PURPOSE",
  BM: "BM",
  FUNNEL: "FUNNEL",
};

export const BUSINESS_FIELDS = [
  {
    id: "외식",
    ko: "외식",
    en: "Dining",
  },
  {
    id: "IT/통신미디어",
    ko: "IT/통신미디어",
    en: "IT/Communication",
  },
  {
    id: "에너지",
    ko: "에너지",
    en: "Energy",
  },
  {
    id: "도소매",
    ko: "도소매",
    en: "Retail",
  },
  {
    id: "건강/의료",
    ko: "건강/의료",
    en: "Health/Medical",
  },
  {
    id: "여행/레져/스포츠",
    ko: "여행/레져/스포츠",
    en: "Travle/Leasure/Sport",
  },
  {
    id: "생활편의",
    ko: "생활편의",
    en: "Living",
  },
  {
    id: "게임",
    ko: "게임",
    en: "Game",
  },
  {
    id: "은행/보험",
    ko: "은행/보험",
    en: "Bank/Insurance",
  },
  {
    id: "문화",
    ko: "문화",
    en: "Culture",
  },
  {
    id: "공유 경제",
    ko: "공유 경제",
    en: "Shared Economy",
  },
  {
    id: "건설/부동산",
    ko: "건설/부동산",
    en: "Construction/Real Estate",
  },
  {
    id: "교육/학문",
    ko: "교육/학문",
    en: "Education",
  },
  {
    id: "유통/물류/무역",
    ko: "유통/물류/무역",
    en: "Distribution/Trading",
  },
  {
    id: "기타",
    ko: "기타",
    en: "Etc",
  },
];
