import { createGlobalStyle, DefaultTheme } from "styled-components";
import { getMediaQueryStrings } from "./mediaQueries";

const theme: DefaultTheme = {
  appMaxWidth: 1280,
  desktopNavHeight: 72,
  tabletNavHeight: 64,
  mobileNavHeight: 70,
  spacing: {
    zero: 0,
    xSmall: 4,
    small: 8,
    normal: 12,
    default: 16,
    regular: 20,
    middle: 24,
    large: 32,
    section: 40,
    xLarge: 48,
  },
  font: {
    head1: 40,
    head2: 32,
    head3: 28,
    body1: 24,
    body2: 20,
    body3: 16,
    caption1: 14,
    caption2: 12,
    caption3: 9,
  },
  color: {
    white: "#FFFFFF",
    black: "#101112",
    gold: "#DAA658",
    goldText: "#FAB44B",
    gray1: "#C4C4C4",
    error: "#f84c4c",
    squareBackground: "#343434",
  },

  ...getMediaQueryStrings(),
};

export default theme;

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    line-height: 1.5;
    text-align: center;
    background-color: ${theme.color.black};
    color: ${theme.color.white};
  }

  body * {
    white-space: pre-line;
  }

  button {
    background-color: inherit;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
  }

  input {
    outline: none;
    border: none;
    border-radius: 0px;
  }

  textarea {
    outline: none;
    border: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff; 
  }
    
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(222, 220, 220); 
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;
