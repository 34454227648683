import { ReactChildrenProp } from "global";
import React, {
  createContext,
  useContext,
  useEffect,
  useState
  } from "react";
import { MIN_SCREEN_WIDTH } from "../../../utilities/theme/mediaQueries";

interface ScreenWidthContextValue {
  screenWidth: number;
  isPc: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

const initialValue: ScreenWidthContextValue = {
  screenWidth: 0,
  isPc: false,
  isTablet: false,
  isMobile: false,
};

const ScreenWidthContext = createContext<ScreenWidthContextValue>(initialValue);

function ScreenWidthProvider({ children }: ReactChildrenProp) {
  const [value, setValue] = useState<ScreenWidthContextValue>(initialValue);

  function handleResize() {
    const currentWidth = window.innerWidth;

    if (currentWidth >= MIN_SCREEN_WIDTH.DESKTOP) {
      setValue({
        screenWidth: currentWidth,
        isPc: true,
        isTablet: false,
        isMobile: false,
      });
    } else if (
      currentWidth < MIN_SCREEN_WIDTH.DESKTOP &&
      currentWidth >= MIN_SCREEN_WIDTH.TABLET
    ) {
      setValue({
        screenWidth: currentWidth,
        isPc: false,
        isTablet: true,
        isMobile: false,
      });
    } else {
      setValue({
        screenWidth: currentWidth,
        isPc: false,
        isTablet: false,
        isMobile: true,
      });
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ScreenWidthContext.Provider value={value}>
      {children}
    </ScreenWidthContext.Provider>
  );
}

export default ScreenWidthProvider;

export function useScreenWidthContext() {
  return useContext(ScreenWidthContext);
}
