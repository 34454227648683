import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PATH_NAMES } from "../utils/router";

function RouterContainer() {
  return (
    <BrowserRouter>
      <Routes>
        {Object.values(PATH_NAMES)?.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default RouterContainer;
