import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import loadingText from "../../../assets/icons/loading_text.svg";
import loadingMotion from "../../../assets/videos/loading_motion.mp4";

function LoadingScreen() {
  return (
    <LoadingScreenWrapper>
      <LoadingVideo muted autoPlay playsInline loop>
        <source src={loadingMotion} />
      </LoadingVideo>
      <LoadingImage
        src={loadingText}
        alt="Footsketch, 풋스케치, MVP 개발, 앱 개발, 웹 개발, 서비스 기획, 기획 자문, 개발 교육"
      />
    </LoadingScreenWrapper>
  );
}

export const LoadingScreenWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  })
);

export const LoadingVideo = styled.video<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "20vw",
    objectFit: "contain",
    marginBottom: theme.spacing.middle,
  })
);

export const LoadingImage = styled.img<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "20vw",
    objectFit: "contain",
  })
);

export default LoadingScreen;
