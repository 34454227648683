import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import { HomeServiceItemProps } from "./HomeService1";
import HomeServiceContactButton from "./HomeServiceContactButton";
import agileImage from "../../../assets/images/agile.svg";
import waterfallImage from "../../../assets/images/waterfall.svg";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import {
  HomeServiceTitle,
  HomeServiceNumbering,
  HomeServiceWrapper,
  HomeServiceRow,
  HomeServiceElement,
  HomeServiceFlexImageWrapper,
  HomeServiceImage,
  HomeServiceSubtitle,
  HomeServiceRecommendationTitle,
  HomeServiceRecommendationsWrapper,
  HomeServiceRecommendationRow,
  HomeTargetIcon,
  HomeServiceViewMoreButton,
  HomeServiceViewMoreIcon,
  HomeServiceDetailWrapper,
  HomeServiceDetailRow,
  HomeServiceDetailCell,
  HomeServiceDetailTitle,
  HomeServiceDetailSubtitle,
  HomeServiceDetailTable,
  HomeServiceDetailTh,
  HomeServiceDetailTd,
} from "../common/styles/homeCommonStyles";

function HomeService2({ serviceRef, service }: HomeServiceItemProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const [showMore, setShowMore] = useState(false);

  return (
    <Wrapper ref={serviceRef}>
      {!isMobile && (
        <HomeServiceRow>
          <HomeServiceElement />

          <HomeServiceElement>
            <HomeServiceNumbering>{service.id}</HomeServiceNumbering>

            <HomeServiceTitle>{t(service.title)}</HomeServiceTitle>
          </HomeServiceElement>
        </HomeServiceRow>
      )}

      <HomeServiceRow style={{ flexDirection: isMobile ? "column" : "row" }}>
        <HomeServiceFlexImageWrapper>
          <HomeServiceImage
            alt={service.alt}
            src={isMobile ? service.mobileImage : service.image}
          />
        </HomeServiceFlexImageWrapper>

        <HomeServiceElement>
          {isMobile && (
            <>
              <HomeServiceNumbering>{service.id}</HomeServiceNumbering>

              <HomeServiceTitle>{t(service.title)}</HomeServiceTitle>
            </>
          )}

          <HomeServiceSubtitle>
            <strong>{t(service.subtitle!)}</strong>
            <br />
            <br />
            {t(service.description)}
          </HomeServiceSubtitle>

          <HomeServiceRecommendationTitle>
            {t("home.serviceRecommendation")}
          </HomeServiceRecommendationTitle>

          <HomeServiceRecommendationsWrapper>
            {service.recommendations!.map((recommendation) => (
              <HomeServiceRecommendationRow key={recommendation.text}>
                <HomeTargetIcon />
                <div>{t(recommendation.text)}</div>
              </HomeServiceRecommendationRow>
            ))}
          </HomeServiceRecommendationsWrapper>

          <HomeServiceViewMoreButton onClick={() => setShowMore(!showMore)}>
            <div>
              {t(showMore ? "home.serviceViewLess" : "home.serviceViewMore")}
            </div>
            <HomeServiceViewMoreIcon isDown={!showMore} />
          </HomeServiceViewMoreButton>
        </HomeServiceElement>
      </HomeServiceRow>

      {showMore && (
        <HomeServiceDetailWrapper>
          <DetailTop>{t("home.service2DetailDescription")}</DetailTop>

          <HomeServiceDetailRow
            style={{ flexDirection: isMobile ? "column-reverse" : "row" }}
          >
            <HomeServiceDetailCell>
              <HomeServiceDetailTitle>
                {t("home.service2Detail1Title")}
              </HomeServiceDetailTitle>
              <HomeServiceDetailSubtitle>
                {t("home.service2Detail1Subtitle")}
              </HomeServiceDetailSubtitle>

              <Image src={waterfallImage} />

              <HomeServiceDetailTable>
                <tbody>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell1Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell1Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell2Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell2Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell3Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell3Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell4Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell4Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell5Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell5Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell6Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell6Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell7Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell7Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell8Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell8Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail1Cell9Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail1Cell9Value")}
                    </HomeServiceDetailTd>
                  </tr>
                </tbody>
              </HomeServiceDetailTable>
            </HomeServiceDetailCell>

            <HomeServiceDetailCell>
              <HomeServiceDetailTitle>
                {t("home.service2Detail2Title")}
              </HomeServiceDetailTitle>
              <HomeServiceDetailSubtitle>
                {t("home.service2Detail2Subtitle")}
              </HomeServiceDetailSubtitle>

              <Image src={agileImage} />

              <HomeServiceDetailTable>
                <tbody>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail2Cell1Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail2Cell1Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail2Cell2Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail2Cell2Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail2Cell3Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail2Cell3Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh>
                      {t("home.service2Detail2Cell4Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service2Detail2Cell4Value")}
                    </HomeServiceDetailTd>
                  </tr>
                </tbody>
              </HomeServiceDetailTable>
            </HomeServiceDetailCell>
          </HomeServiceDetailRow>
        </HomeServiceDetailWrapper>
      )}
      <HomeServiceContactButton label={t("home.serviceContact")} />
    </Wrapper>
  );
}

const Wrapper = styled(HomeServiceWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.section * 2}px ${theme.spacing.section}px`,

    [theme.media.down["DESKTOP"]]: {
      padding: `${(theme.spacing.section * 3) / 2}px 0px`,
    },

    [theme.media.down["TABLET"]]: {
      paddingBottom: theme.spacing.section,
      paddingTop: theme.spacing.section,
    },
  })
);

const DetailTop = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  padding: `${theme.spacing.section}px ${(theme.spacing.section * 3) / 2}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    fontSize: theme.font.body3,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.regular}px ${theme.spacing.default}px`,
  },
}));

const Image = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  objectFit: "contain",
  marginBottom: theme.spacing.middle,
}));

export default HomeService2;
