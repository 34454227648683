import { MediaQueryDetailType, MediaQueryType } from "styled-components";

export const MIN_SCREEN_WIDTH: { [key: string]: number } = {
  DESKTOP: 1180,
  TABLET: 600,
};

export function getMediaQueryStrings(): { media: MediaQueryType } {
  const up: MediaQueryDetailType = {};
  const down: MediaQueryDetailType = {};

  Object.keys(MIN_SCREEN_WIDTH).map((key) => {
    up[key] = `@media (min-width: ${MIN_SCREEN_WIDTH[key]}px)`;
    down[key] = `@media (max-width: ${MIN_SCREEN_WIDTH[key]}px)`;
  });

  return { media: { up, down } };
}
