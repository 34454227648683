import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { ContactSectionTitle } from "../common/styles/contactCommonStyles";

interface ContactTextareaProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function ContactTextarea({
  label,
  placeholder,
  value,
  onChange,
}: ContactTextareaProps) {
  return (
    <Wrapper>
      <ContactSectionTitle>{label}</ContactSectionTitle>
      <Textarea placeholder={placeholder} value={value} onChange={onChange} />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.default,
  width: "100%",
}));

interface InputProps extends ThemedComponentProps {
  error?: boolean;
}

const Textarea = styled.textarea<any>(({ theme, error }: InputProps) => ({
  width: "100%",
  backgroundColor: error ? "rgba(248,76,76,0.1)" : "rgba(196,196,196,0.1)",
  border: `1px solid ${error ? theme.color.error : theme.color.gray1}`,
  padding: theme.spacing.regular,
  color: theme.color.black,
  fontSize: theme.font.body3,
  minHeight: 184,

  "::placeholder": {
    color: theme.color.gray1,
  },

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
  },
}));

export default ContactTextarea;
