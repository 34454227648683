import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import fileIcon from "../../../assets/icons/file.svg";
import { ContactSectionTitle } from "../common/styles/contactCommonStyles";

interface ContactFileUploadProps {
  label: string;
  placeholder: string;
  file: any;
  onChange: (file: any) => void;
}

function ContactFileUpload({
  label,
  placeholder,
  file,
  onChange,
}: ContactFileUploadProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContactSectionTitle>{label}</ContactSectionTitle>
      <FileWrapper>
        <FileUploader
          hoverTitle={t("common.drop")}
          dropMessageStyle={{
            backgroundColor: "red",
            transform: "none",
            left: "-100%",
            right: "0",
          }}
          file={file}
          types={[
            "png",
            "jpeg",
            "jpg",
            "pdf",
            "xlsx",
            "xls",
            "ppt",
            "pptx",
            "doc",
            "docs",
          ]}
          handleChange={onChange}
        >
          <FileButton>
            <FileIcon />
            <div>{file ? file?.name : placeholder}</div>
          </FileButton>
        </FileUploader>
      </FileWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.default,
  width: "100%",
}));

const FileWrapper = styled.div<any>(() => ({
  width: "100%",
  position: "relative",
}));

const FileButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.section}px 0px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing.small,
  fontSize: theme.font.body3,
  backgroundColor: "rgba(196,196,196,0.1)",
  border: `1px solid ${theme.color.gray1}`,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
  },
}));

const FileIcon = styled.img.attrs(() => ({
  src: fileIcon,
}))<any>(() => ({
  width: 26,
  height: 32,
}));

export default ContactFileUpload;
