import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "./index.css";
import "./lib/i18n/i18n";
import ScreenWidthProvider from "./module/common/provider/ScreenWidthProvider";
import LoadingScreen from "./module/common/screens/LoadingScreen";
import reportWebVitals from "./reportWebVitals";
import theme, { GlobalStyle } from "./utilities/theme/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Suspense fallback={<LoadingScreen />}>
        <ScreenWidthProvider>
          <App />
        </ScreenWidthProvider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
