import { useEffect, useState } from "react";
import { useGetLatestPrivacyPolicyLazyQuery } from "../../../lib/apollo/graphql/generated";

function useContactAgreeSection() {
  const [showPolicy, setShowPolicy] = useState(false);

  const [getLatestPrivacyPolicy, { data, loading }] =
    useGetLatestPrivacyPolicyLazyQuery();

  useEffect(() => {
    if (showPolicy) {
      getLatestPrivacyPolicy();
    }
  }, [showPolicy]);

  function toggleShowPolicy() {
    setShowPolicy(!showPolicy);
  }

  return {
    models: {
      showPolicy,
      loading,
      data: data?.getLatestPrivacyPolicy,
    },
    operations: {
      toggleShowPolicy,
    },
  };
}

export default useContactAgreeSection;
