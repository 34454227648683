import styled, { ThemedComponentProps } from "styled-components";
import arrowUpIcon from "../../../../assets/icons/chevron_up_black.svg";
import targetIcon from "../../../../assets/icons/target.svg";

export const HomeSubtitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body1,
    marginBottom: theme.spacing.default,
    color: theme.color.gray1,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body3,
      marginBottom: theme.spacing.small,
    },

    [theme.media.down["TABLET"]]: {
      fontSize: theme.font.caption1,
    },
  })
);

export const HomeTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head1,
  marginBottom: theme.spacing.section,
  fontWeight: "bold",
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.head3,
    marginBottom: theme.spacing.middle,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body2,
  },
}));

export const HomeServiceWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    paddingTop: theme.spacing.section * 3,
    paddingBottom: theme.spacing.regular * 5,
    paddingLeft: theme.spacing.section,
    paddingRight: theme.spacing.section,
    width: "100%",
    textAlign: "left",
    color: theme.color.white,

    [theme.media.down["DESKTOP"]]: {
      padding: `${(theme.spacing.section * 3) / 2}px 0px`,
    },

    [theme.media.down["TABLET"]]: {
      paddingBottom: theme.spacing.section,
    },
  })
);

export const HomeServiceNumbering = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontFamily: "Playfair Display",
    fontSize: 51,
    color: theme.color.black,
    WebkitTextStroke: `1px ${theme.color.gray1}`,

    [theme.media.down["DESKTOP"]]: {
      fontSize: 40,
    },

    [theme.media.down["TABLET"]]: {
      fontSize: 32,
    },
  })
);

export const HomeServiceTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.head1,
    fontWeight: "bold",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.head3,
    },

    [theme.media.down["TABLET"]]: {
      fontSize: theme.font.body1,
      marginBottom: theme.spacing.default,
    },
  })
);

export const HomeServiceRow = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "justify-content",
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      marginBottom: theme.spacing.regular,
    },

    [theme.media.down["TABLET"]]: {
      flexDirection: "column-reverse",
      marginBottom: theme.spacing.regular,
    },
  })
);

export const HomeServiceElement = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    flex: 1,
    flexBasis: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: `0px ${theme.spacing.section}px`,

    [theme.media.down["DESKTOP"]]: {
      padding: `0px ${theme.spacing.regular}px`,
    },

    [theme.media.down["TABLET"]]: {
      padding: 0,
    },
  })
);

export const HomeServiceFlexImageWrapper = styled(HomeServiceElement)<any>(
  ({ theme }: ThemedComponentProps) => ({
    flex: 1,
    flexBasis: "50%",
    paddingBottom: theme.spacing.small,
    alignItems: "center",
    justifyContent: "flex-start",

    [theme.media.down["TABLET"]]: {
      paddingBottom: 0,
      marginBottom: theme.spacing.middle,
    },
  })
);

export const HomeServiceImage = styled.img<any>(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

export const HomeServiceSubtitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body2,
    marginBottom: (theme.spacing.section * 3) / 2,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption1,
      marginBottom: theme.spacing.section,
    },

    [theme.media.down["TABLET"]]: {
      fontSize: theme.font.body3,
      marginBottom: theme.spacing.section,
    },
  })
);

export const HomeServiceRecommendationTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,
    fontWeight: "bold",
    color: theme.color.gold,
    marginBottom: theme.spacing.normal,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption1,
      marginBottom: theme.spacing.small,
    },
  })
);

export const HomeServiceRecommendationsWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing.small,
    marginBottom: theme.spacing.large,

    [theme.media.down["DESKTOP"]]: {
      marginBottom: theme.spacing.middle,
    },
  })
);

export const HomeServiceRecommendationRow = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.default,
    fontSize: theme.font.body3,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption2,
      gap: theme.spacing.small,
    },

    [theme.media.down["TABLET"]]: {
      fontSize: theme.font.caption1,
    },
  })
);

export const HomeTargetIcon = styled.img.attrs(() => ({
  src: targetIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 32,
  height: 32,

  [theme.media.down["DESKTOP"]]: {
    width: 20,
    height: 20,
  },

  [theme.media.down["TABLET"]]: {
    width: 24,
    height: 24,
  },
}));

export const HomeServiceViewMoreButton = styled.button<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: 304,
    padding: `${theme.spacing.normal}px ${theme.spacing.xSmall}px`,
    backgroundColor: theme.color.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.font.body3,
    color: theme.color.black,
    gap: theme.spacing.xSmall,

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.small}px ${theme.spacing.section}px`,
      width: "fit-content",
      fontSize: theme.font.caption2,
      gap: theme.spacing.xSmall / 2,
    },

    [theme.media.down["TABLET"]]: {
      width: "100%",
      padding: `${theme.spacing.small}px 0px`,
      fontSize: theme.font.caption1,
      gap: theme.spacing.xSmall,
    },
  })
);

interface HomeServiceViewMoreIconProps extends ThemedComponentProps {
  isDown: boolean;
}

export const HomeServiceViewMoreIcon = styled.img.attrs(() => ({
  src: arrowUpIcon,
}))<any>(({ theme, isDown }: HomeServiceViewMoreIconProps) => ({
  width: 24,
  height: 24,
  transform: `rotate(${isDown ? "180deg" : "0deg"})`,

  [theme.media.down["DESKTOP"]]: {
    width: 20,
    height: 20,
  },
}));

export const HomeServiceDetailWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    backgroundColor: theme.color.squareBackground,
    margin: `0px ${theme.spacing.section}px`,
    marginBottom: theme.spacing.section,

    [theme.media.down["DESKTOP"]]: {
      margin: 0,
      marginBottom: theme.spacing.regular,
    },
  })
);

export const HomeServiceDetailRow = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",

    [theme.media.down["TABLET"]]: {
      flexDirection: "column",
    },
  })
);

export const HomeServiceDetailCell = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    flex: 1,
    flexBasis: "50%",
    padding: `${theme.spacing.section}px ${(theme.spacing.section * 3) / 2}px`,

    [theme.media.down["DESKTOP"]]: {
      padding: theme.spacing.regular,
    },

    [theme.media.down["TABLET"]]: {
      padding: `${theme.spacing.regular}px ${theme.spacing.default}px`,
    },
  })
);

export const HomeServiceDetailTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body2,
    fontWeight: "bold",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body3,
      marginBottom: theme.spacing.xSmall,
    },
  })
);

export const HomeServiceDetailSubtitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.caption1,
    color: theme.color.gray1,
    marginBottom: theme.spacing.default,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption2,
    },
  })
);

export const HomeServiceDetailTable = styled.table<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,
    color: theme.color.black,
    width: "100%",
    borderSpacing: 0,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption1,
    },
  })
);

export const HomeServiceDetailTh = styled.th<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    backgroundColor: "#f6f6f6",
    border: `1px solid ${theme.color.gray1}`,
    wordBreak: "break-all",
  })
);

export const HomeServiceDetailTd = styled.td<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    border: `1px solid ${theme.color.gray1}`,
    borderLeft: "none",
    backgroundColor: theme.color.white,
  })
);
