import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";

function HomeBreak() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content>{t("home.break")}</Content>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.appMaxWidth,

  [theme.media.down["DESKTOP"]]: {
    padding: `0px ${theme.spacing.large}px`,
  },

  [theme.media.down["DESKTOP"]]: {
    padding: `0px ${theme.spacing.default}px`,
  },
}));

const Content = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.section}px 0px`,
  textAlign: "center",
  fontSize: theme.font.body1,
  color: theme.color.goldText,
  borderTop: `1px solid ${theme.color.gold}`,
  borderBottom: `1px solid ${theme.color.gold}`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.regular}px 0px`,
    fontSize: theme.font.body3,
  },

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },
}));

export default HomeBreak;
