import ContactContainer from "../../../module/contact/container/ContactContainer";
import HomeContainer from "../../../module/home/container/HomeContainer";

export const PATH_NAMES = {
  HOME: {
    path: "/",
    element: <HomeContainer />,
  },
  CONTACT: {
    path: "/contact",
    element: <ContactContainer />,
  },
  CONTACTED: {
    path: "/contacted",
    element: <ContactContainer />,
  },
};
