import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import HomeService1 from "./HomeService1";
import HomeService2 from "./HomeService2";
import HomeService34 from "./HomeService34";
import service1MobileImage from "../../../assets/images/service1_mobile.webp";
import service1Image from "../../../assets/images/service1.webp";
import service2MobileImage from "../../../assets/images/service2_mobile.webp";
import service2Image from "../../../assets/images/service2.webp";
import service3Image from "../../../assets/images/service3.webp";
import service4Image from "../../../assets/images/service4.webp";
import { HomeTitle } from "../common/styles/homeCommonStyles";

const SERVICES = [
  {
    id: "01",
    image: service1Image,
    mobileImage: service1MobileImage,
    alt: "5일 완성 컨텐츠 앱 개발",
    shortTitle: "home.service1ShortTitle",
    title: "home.service1Title",
    subtitle: "home.service1Subtitle",
    description: "home.service1Description",
    recommendations: [
      {
        text: "home.service1Recommendation1",
      },
      {
        text: "home.service1Recommendation2",
      },
      {
        text: "home.service1Recommendation3",
      },
      {
        text: "home.service1Recommendation4",
      },
    ],
  },
  {
    id: "02",
    image: service2Image,
    mobileImage: service2MobileImage,
    alt: "고객 맞춤 앱 개발",
    shortTitle: "home.service2ShortTitle",
    title: "home.service2Title",
    subtitle: "home.service2Subtitle",
    description: "home.service2Description",
    recommendations: [
      {
        text: "home.service2Recommendation1",
      },
      {
        text: "home.service2Recommendation2",
      },
      {
        text: "home.service2Recommendation3",
      },
      {
        text: "home.service2Recommendation4",
      },
    ],
  },
  {
    id: "03",
    image: service3Image,
    alt: "서비스 기획, 자문",
    shortTitle: "home.service3ShortTitle",
    title: "home.service3Title",
    description: "home.service3Description",
  },
  {
    id: "04",
    image: service4Image,
    alt: "개발 직무 교육",
    shortTitle: "home.service4ShortTitle",
    title: "home.service4Title",
    description: "home.service4Description",
  },
];

export interface HomeServiceProps {
  service1Ref: React.RefObject<HTMLDivElement>;
  service2Ref: React.RefObject<HTMLDivElement>;
  service3Ref: React.RefObject<HTMLDivElement>;
  service4Ref: React.RefObject<HTMLDivElement>;
  serviceSummaryRef?: React.RefObject<HTMLDivElement>;
}

function HomeService({
  service1Ref,
  service2Ref,
  service3Ref,
  service4Ref,
}: HomeServiceProps) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Subtitle>OUR SERVICE</Subtitle>
      <HomeTitle>{t("home.serviceTitle")}</HomeTitle>

      <ShortTitlesWrapper>
        {SERVICES.map((service, index) => {
          const serviceRef =
            index === 0
              ? service1Ref
              : index === 1
              ? service2Ref
              : index === 2
              ? service3Ref
              : service4Ref;

          return (
            <ShortTitle
              key={service.shortTitle}
              onClick={() =>
                serviceRef?.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {service.id} {t(service.shortTitle)}
            </ShortTitle>
          );
        })}
      </ShortTitlesWrapper>

      {/* SERVICE 1 */}
      <HomeService1 serviceRef={service1Ref} service={SERVICES[0]} />
      <HomeService2 serviceRef={service2Ref} service={SERVICES[1]} />
      <HomeService34Wrapper>
        <HomeService34 serviceRef={service3Ref} service={SERVICES[2]} />
        <HomeService34 serviceRef={service4Ref} service={SERVICES[3]} />
      </HomeService34Wrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  maxWidth: theme.appMaxWidth,
  width: "100%",
  padding: `${theme.spacing.section * 3}px ${theme.spacing.large}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    paddingTop: (theme.spacing.section * 3) / 2,
    paddingBottom: theme.spacing.section * 2,
  },

  [theme.media.down["TABLET"]]: {
    paddingTop: theme.spacing.section,
    paddingBottom: (theme.spacing.section * 3) / 2,
    paddingLeft: theme.spacing.default,
    paddingRight: theme.spacing.default,
  },
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontFamily: "Playfair Display",
  fontWeight: "bold",
  color: theme.color.goldText,
  fontSize: 24,
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: 16,
  },

  [theme.media.down["TABLET"]]: {
    fontSize: 14,
    marginBottom: theme.spacing.xSmall,
  },
}));

const ShortTitlesWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing.regular}px 0px`,
    gap: (theme.spacing.section * 3) / 2,
    fontSize: theme.font.body2,
    borderColor: theme.color.goldText,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    width: "100%",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption1,
      gap: theme.spacing.middle,
    },

    [theme.media.down["TABLET"]]: {
      gap: 0,
      flexWrap: "wrap",
      padding: 0,
    },
  })
);

const ShortTitle = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  "&:hover": {
    fontWeight: "bold",
  },

  [theme.media.down["TABLET"]]: {
    flex: 1,
    flexBasis: "48%",
    padding: theme.spacing.normal,
  },
}));

const HomeService34Wrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",

    [theme.media.down["TABLET"]]: {
      flexDirection: "column",
      paddingTop: theme.spacing.section,
      paddingBottom: (theme.spacing.section * 3) / 2,
      gap: theme.spacing.section,
    },
  })
);

export default HomeService;
