import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import HomeServiceContactButton from "./HomeServiceContactButton";
import plusIcon from "../../../assets/icons/plus.svg";
import detailImage from "../../../assets/images/service1_detail.svg";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import {
  HomeServiceDetailCell,
  HomeServiceDetailRow,
  HomeServiceDetailSubtitle,
  HomeServiceDetailTable,
  HomeServiceDetailTd,
  HomeServiceDetailTh,
  HomeServiceDetailTitle,
  HomeServiceElement,
  HomeServiceFlexImageWrapper,
  HomeServiceImage,
  HomeServiceNumbering,
  HomeServiceRecommendationRow,
  HomeServiceRecommendationsWrapper,
  HomeServiceRecommendationTitle,
  HomeServiceRow,
  HomeServiceSubtitle,
  HomeServiceTitle,
  HomeServiceViewMoreButton,
  HomeServiceViewMoreIcon,
  HomeServiceDetailWrapper,
  HomeServiceWrapper,
  HomeTargetIcon,
} from "../common/styles/homeCommonStyles";

export interface HomeServiceItemProps {
  serviceRef: React.RefObject<HTMLDivElement>;
  service:
    | {
        id: string;
        image: string;
        mobileImage: string;
        alt: string;
        shortTitle: string;
        title: string;
        subtitle: string;
        description: string;
        recommendations: {
          text: string;
        }[];
      }
    | {
        id: string;
        image: string;
        mobileImage?: string;
        alt: string;
        shortTitle: string;
        title: string;
        description: string;
        subtitle?: undefined;
        recommendations?: undefined;
      };
}

function HomeService1({ serviceRef, service }: HomeServiceItemProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const [showMore, setShowMore] = useState(false);

  return (
    <HomeServiceWrapper ref={serviceRef}>
      {!isMobile && (
        <HomeServiceRow>
          <HomeServiceElement>
            <HomeServiceNumbering>{service.id}</HomeServiceNumbering>

            <HomeServiceTitle>{t(service.title)}</HomeServiceTitle>
          </HomeServiceElement>
          <HomeServiceElement />
        </HomeServiceRow>
      )}

      <HomeServiceRow>
        <HomeServiceElement>
          {isMobile && (
            <>
              <HomeServiceNumbering>{service.id}</HomeServiceNumbering>

              <HomeServiceTitle>{t(service.title)}</HomeServiceTitle>
            </>
          )}
          <HomeServiceSubtitle>
            <strong>{t(service.subtitle!)}</strong>
            <br />
            <br />
            {t(service.description)}
          </HomeServiceSubtitle>

          <HomeServiceRecommendationTitle>
            {t("home.serviceRecommendation")}
          </HomeServiceRecommendationTitle>

          <HomeServiceRecommendationsWrapper>
            {service.recommendations!.map((recommendation) => (
              <HomeServiceRecommendationRow key={recommendation.text}>
                <HomeTargetIcon />
                <div>{t(recommendation.text)}</div>
              </HomeServiceRecommendationRow>
            ))}
          </HomeServiceRecommendationsWrapper>

          <HomeServiceViewMoreButton onClick={() => setShowMore(!showMore)}>
            <div>
              {t(showMore ? "home.serviceViewLess" : "home.serviceViewMore")}
            </div>
            <HomeServiceViewMoreIcon isDown={!showMore} />
          </HomeServiceViewMoreButton>
        </HomeServiceElement>
        <HomeServiceFlexImageWrapper>
          <HomeServiceImage
            alt={service.alt}
            src={isMobile ? service.mobileImage : service.image}
          />
        </HomeServiceFlexImageWrapper>
      </HomeServiceRow>

      {showMore && (
        <HomeServiceDetailWrapper>
          <DetailTop>
            <div>{t("home.service1DetailDescription")}</div>
            <DetailTopImage src={detailImage} />
          </DetailTop>

          <HomeServiceDetailRow>
            <HomeServiceDetailCell>
              <HomeServiceDetailTitle>
                {t("home.service1Detail1Title")}
              </HomeServiceDetailTitle>
              <HomeServiceDetailSubtitle>
                {t("home.service1Detail1Subtitle")}
              </HomeServiceDetailSubtitle>

              <HomeServiceDetailTable>
                <tbody>
                  <tr>
                    <HomeServiceDetailTh style={{ width: "40%" }}>
                      {t("home.service1Detail1Cell1Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service1Detail1Cell1Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh style={{ width: "40%" }}>
                      {t("home.service1Detail1Cell2Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service1Detail1Cell2Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh style={{ width: "40%" }}>
                      {t("home.service1Detail1Cell3Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service1Detail1Cell3Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh style={{ width: "40%" }}>
                      {t("home.service1Detail1Cell4Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service1Detail1Cell4Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh style={{ width: "40%" }}>
                      {t("home.service1Detail1Cell5Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service1Detail1Cell5Value")}
                    </HomeServiceDetailTd>
                  </tr>
                  <tr>
                    <HomeServiceDetailTh style={{ width: "40%" }}>
                      {t("home.service1Detail1Cell6Title")}
                    </HomeServiceDetailTh>
                    <HomeServiceDetailTd>
                      {t("home.service1Detail1Cell6Value")}
                    </HomeServiceDetailTd>
                  </tr>
                </tbody>
              </HomeServiceDetailTable>
            </HomeServiceDetailCell>

            <HomeServiceDetailCell>
              <HomeServiceDetailTitle>
                {t("home.service1Detail2Title")}
              </HomeServiceDetailTitle>
              <HomeServiceDetailSubtitle>
                {t("home.service1Detail2Subtitle")}
              </HomeServiceDetailSubtitle>
              <OptionsWrapper>
                {Array(7)
                  .fill(0)
                  .map((value, index) => (
                    <Option key={index}>
                      <Plus />
                      <div>
                        {t(`home.service1Detail2Cell${index + 1}Value`)}
                      </div>
                    </Option>
                  ))}
              </OptionsWrapper>
            </HomeServiceDetailCell>
          </HomeServiceDetailRow>
        </HomeServiceDetailWrapper>
      )}
      <HomeServiceContactButton label={t("home.serviceContact")} />
    </HomeServiceWrapper>
  );
}

const DetailTop = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "space-between",
  fontSize: theme.font.body2,
  fontWeight: "bold",
  padding: `${theme.spacing.section}px ${(theme.spacing.section * 3) / 2}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    fontSize: theme.font.body3,
  },

  [theme.media.down["TABLET"]]: {
    flexDirection: "column-reverse",
    padding: `${theme.spacing.regular}px ${theme.spacing.default}px`,
    fontSize: theme.font.body3,
    alignItems: "center",
  },
}));

const DetailTopImage = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  height: "100%",
  objectFit: "contain",

  [theme.media.down["TABLET"]]: {
    width: "40%",
    marginBottom: theme.spacing.section,
  },
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: theme.spacing.small,
}));

const Option = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px ${theme.spacing.middle}px`,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  fontSize: theme.font.body3,
  color: theme.color.black,
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.gray1}`,
  borderRadius: 2,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.small}px ${theme.spacing.default}px`,
    gap: theme.spacing.xSmall,
    fontSize: theme.font.caption1,
  },
}));

const Plus = styled.img.attrs(() => ({
  src: plusIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 24,
  height: 24,

  [theme.media.down["DESKTOP"]]: {
    width: 20,
    height: 20,
  },
}));

export default HomeService1;
