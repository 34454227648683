import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useContactContainer from "./ContactContainer.hook";
import { PATH_NAMES } from "../../../lib/router/utils/router";
import { OPTION_TYPES } from "../../../utilities/constants";
import NavigationBar from "../../common/components/NavigationBar";
import ContactAgreeSection from "../components/ContactAgreeSection";
import ContactBusinessFieldSection from "../components/ContactBusinessFieldSection";
import ContactFileUpload from "../components/ContactFileUpload";
import ContactInput from "../components/ContactInput";
import ContactOptionsSection from "../components/ContactOptionsSection";
import ContactRadioSection from "../components/ContactRadioSection";
import ContactTextarea from "../components/ContactTextarea";

function ContactContainer() {
  const { t } = useTranslation();
  const { pathname, state: routeState } = useLocation();
  const navigate = useNavigate();

  const isContacted = pathname === PATH_NAMES.CONTACTED.path;

  const {
    models: { loading, data, state, createLoading },
    operations: {
      onInputChange,
      onTextareaChange,
      onBusinessFieldChange,
      onOptionsChange,
      onFileChange,
      onEmailFocus,
      onEmailBlur,
      toggleAgreed,
      isSubmitDisabled,
      onSubmit,
    },
  } = useContactContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <NavigationBar introInView={true} isHome={false} />
      {!isContacted && (
        <>
          <Title>{t("contact.mainTitle")}</Title>
          <Subtitle>
            {t("contact.mainSubtitle")}
            <br />
            <Email href="mailto:footsketch@footsketch.com">
              footsketch@footsketch.com
            </Email>
          </Subtitle>
        </>
      )}

      <OptionsWrapper>
        {!isContacted ? (
          <>
            <ContactOptionsSection
              type={OPTION_TYPES.PRODUCT}
              options={data?.productOptions!}
              selectedOptionIds={state.productOptionIds}
              onOptionsChange={onOptionsChange}
            />
            <ContactOptionsSection
              type={OPTION_TYPES.READINESS}
              options={data?.readinessOptions!}
              isDuplicable={false}
              selectedOptionIds={state.readinessOptionIds}
              onOptionsChange={onOptionsChange}
            />
            <ContactOptionsSection
              type={OPTION_TYPES.PURPOSE}
              options={data?.purposeOptions!}
              selectedOptionIds={state.purposeOptionIds}
              onOptionsChange={onOptionsChange}
            />

            <ContactInput
              label={t("contact.budgetLabel")}
              placeholder={t("contact.budgetPlaceholder")}
              value={state.budget}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "budget")
              }
            />
            <ContactOptionsSection
              type={OPTION_TYPES.BM}
              options={data?.bmOptions!}
              selectedOptionIds={state.bmOptionIds}
              onOptionsChange={onOptionsChange}
            />
            <ContactInput
              label={t("contact.teamLabel")}
              placeholder={t("contact.teamPlaceholder")}
              value={state.team}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "team")
              }
            />
            <ContactBusinessFieldSection
              selectedField={state.businessField}
              onChange={onBusinessFieldChange}
            />
            <ContactInput
              label={t("contact.managerNameLabel")}
              placeholder={t("contact.managerNamePlaceholder")}
              value={state.managerName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "managerName")
              }
            />
            <ContactInput
              label={t("contact.managerPositionLabel")}
              placeholder={t("contact.managerPositionPlaceholder")}
              value={state.managerPosition}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "managerPosition")
              }
            />
            <ContactInput
              type="tel"
              label={t("contact.phoneLabel")}
              placeholder={t("contact.phonePlaceholder")}
              value={state.phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "phone")
              }
            />
            <ContactInput
              type="email"
              label={t("contact.emailLabel")}
              placeholder={t("contact.emailPlaceholder")}
              value={state.email}
              error={state.emailError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "email")
              }
              onFocus={onEmailFocus}
              onBlur={onEmailBlur}
            />
            <ContactRadioSection
              type={OPTION_TYPES.FUNNEL}
              options={data?.funnelOptions!}
              selectedOptionIds={state.funnelOptionIds}
              onOptionsChange={onOptionsChange}
            />
            <ContactTextarea
              label={t("contact.descriptionLabel")}
              placeholder={t("contact.descriptionPlaceholder")}
              value={state.description}
              onChange={onTextareaChange}
            />

            <ContactFileUpload
              label={t("contact.fileLabel")}
              placeholder={t("contact.filePlaceholder")}
              file={state.mediaState}
              onChange={onFileChange}
            />
            <ContactAgreeSection
              agreed={state.agreed}
              toggleAgreed={toggleAgreed}
            />
            <SubmitButton
              disabled={createLoading || isSubmitDisabled()}
              onClick={onSubmit}
            >
              {createLoading ? t("common.submitting") : t("common.submit")}
            </SubmitButton>
          </>
        ) : (
          <>
            <ContactedText>
              {routeState?.name
                ? routeState?.name + t("common.sir") + ", "
                : ""}
              {t("contact.contacted")}
            </ContactedText>
            <HomeButton onClick={() => navigate(PATH_NAMES.HOME.path)}>
              {t("contact.goHome")}
            </HomeButton>
          </>
        )}
      </OptionsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: theme.appMaxWidth,
  margin: "0px auto",
  paddingTop: theme.desktopNavHeight + theme.spacing.section * 2,
  paddingBottom: theme.spacing.section * 6,

  [theme.media.down["DESKTOP"]]: {
    paddingTop: theme.tabletNavHeight + theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    paddingTop: theme.mobileNavHeight + theme.spacing.section,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head3,
  fontWeight: "bold",
  color: theme.color.white,
  marginBottom: theme.spacing.middle,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
    marginBottom: theme.spacing.default,
  },
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  color: theme.color.gray1,
  marginBottom: theme.spacing.section * 2,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.section,
  },
}));

const Email = styled.a<any>(({ theme }: ThemedComponentProps) => ({
  textDecoration: "underline",
  textDecorationColor: theme.color.white,
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.section,
  backgroundColor: theme.color.white,
  color: theme.color.black,
  width: "100%",
  maxWidth: 841,
  gap: (theme.spacing.section * 3) / 2,
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",

  [theme.media.down["DESKTOP"]]: {
    maxWidth: 678,
    padding: theme.spacing.large,
    gap: theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.default}px`,
    gap: theme.spacing.large,
  },
}));

const SubmitButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.default}px 0px`,
  textAlign: "center",
  backgroundColor: theme.color.gold,
  fontSize: theme.font.body2,

  "&:disabled": {
    pointerEvents: "none",
    opacity: 0.5,
  },

  [theme.media.down["DESKTOP"]]: {
    padding: `14px 0px`,
    fontSize: theme.font.body3,
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.default}px 0px`,
    fontSize: theme.font.body2,
  },
}));

const ContactedText = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  fontSize: theme.font.body2,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: -theme.spacing.middle,
}));

const HomeButton = styled(SubmitButton)<any>(() => ({
  width: 167,
  margin: "0px auto",
}));

export default ContactContainer;
