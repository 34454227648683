import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import useContactAgreeSection from "./ContactAgreeSection.hook";
import checkedIcon from "../../../assets/icons/checked.svg";
import uncheckedIcon from "../../../assets/icons/unchecked.svg";

interface ContactAgreeSectionProps {
  agreed: boolean;
  toggleAgreed: () => void;
}

function ContactAgreeSection({
  agreed,
  toggleAgreed,
}: ContactAgreeSectionProps) {
  const { t } = useTranslation();
  const {
    models: { showPolicy, loading, data },
    operations: { toggleShowPolicy },
  } = useContactAgreeSection();

  return (
    <>
      <Wrapper>
        <RadioButton onClick={toggleAgreed}>
          <Radio src={agreed ? checkedIcon : uncheckedIcon} />
        </RadioButton>
        <PolicyPhraseWrapper>
          <Phrase onClick={toggleShowPolicy}>{t("contact.policy1")}</Phrase>
          <span>{t("contact.policy2")}</span>
        </PolicyPhraseWrapper>
      </Wrapper>
      {showPolicy && (
        <PolicyOverlay>
          <PolicyLayer onClick={toggleShowPolicy} />
          <PolicyWrapper>
            <CloseButton onClick={toggleShowPolicy}>
              {t("common.close")}
            </CloseButton>
            {!loading && (
              <div dangerouslySetInnerHTML={{ __html: data?.policy! }} />
            )}
          </PolicyWrapper>
        </PolicyOverlay>
      )}
    </>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  fontSize: theme.font.body3,
}));

const RadioButton = styled.button<any>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Radio = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 20,
  height: 20,

  [theme.media.down["TABLET"]]: {
    width: 24,
    height: 24,
  },
}));

const PolicyPhraseWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    alignItems: "center",
    fontSize: theme.font.body3,
  })
);

const Phrase = styled.div<any>(() => ({
  textDecoration: "underline",
  cursor: "pointer",
}));

const PolicyOverlay = styled.div<any>(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  backgroundColor: "rgba(0,0,0,0.2)",
}));

const PolicyLayer = styled.div<any>(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "transparent",
}));

const PolicyWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "70%",
  backgroundColor: "white",
  textAlign: "left",
  padding: theme.spacing.middle,
  overflow: "auto",
  zIndex: 100,
}));

const CloseButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  top: theme.spacing.normal,
  right: theme.spacing.normal,
}));

export default ContactAgreeSection;
