import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import { OptionType } from "../../../lib/apollo/graphql/generated";

interface ContactOptionProps {
  option: {
    __typename?: "Option" | undefined;
    id: string;
    type: OptionType;
    title: string;
    titleEng: string;
    active: boolean;
    sort: number;
    icon?:
      | {
          __typename?: "Media" | undefined;
          id: string;
          uri: string;
        }
      | null
      | undefined;
  } | null;
  selected: boolean;
  onClick: () => void;
}

function ContactOption({ option, selected, onClick }: ContactOptionProps) {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  return (
    <Wrapper selected={selected} onClick={onClick}>
      {option?.icon && <Icon src={option?.icon?.uri} />}
      <OptionTitle>
        {currentLanguage === "ko" ? option?.title : option?.titleEng}
      </OptionTitle>
    </Wrapper>
  );
}

interface WrapperProps extends ThemedComponentProps {
  selected: boolean;
}

const Wrapper = styled.button<any>(({ theme, selected }: WrapperProps) => ({
  flexBasis: "31%",
  padding: `${theme.spacing.middle}px ${theme.spacing.section}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing.small,
  border: `1px solid ${selected ? theme.color.gold : "rgba(196,196,196,0.5)"}`,
  backgroundColor: selected ? "rgba(255,213,150,0.2)" : theme.color.white,

  "&:hover": {
    borderColor: theme.color.gold,
  },

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.regular}px ${theme.spacing.normal}px`,
  },

  [theme.media.down["TABLET"]]: {
    padding: theme.spacing.default,
    gap: theme.spacing.xSmall / 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
}));

const Icon = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 28,
  height: 28,
  objectFit: "cover",

  [theme.media.down["DESKTOP"]]: {
    width: 24,
    height: 24,
  },

  [theme.media.down["TABLET"]]: {
    width: 20,
    height: 20,
  },
}));

const OptionTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
  fontSize: theme.font.body3,
  height: "100%",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },

  [theme.media.down["TABLET"]]: {
    textAlign: "left",
    justifyContent: "flex-start",
    fontSize: theme.font.body3,
  },
}));

export default ContactOption;
