import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import logo from "../../../assets/images/footsketch_logo.svg";
import kakaoChannel from "../../../assets/images/kakao.svg";
import { PATH_NAMES } from "../../../lib/router/utils/router";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface FooterProps {
  serviceSummaryRef?: React.RefObject<HTMLDivElement>;
  projectsRef?: React.RefObject<HTMLDivElement>;
}

function Footer({ serviceSummaryRef, projectsRef }: FooterProps) {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  return (
    <Wrapper>
      <Row>
        <Info>
          <Logo alt="Footsketch, 풋스케치, MVP 개발, 앱 개발, 웹 개발, 서비스 기획, 기획 자문, 개발 교육" />
          <Text>
            {t("footer.companyName")}
            <br />
            {t("footer.address")}
          </Text>
          <EmailWrapper>
            <Email href="mailto:footsketch@footsketch.com">
              footsketch@footsketch.com
            </Email>
          </EmailWrapper>
          <div>
            <KakaoChannel href="http://pf.kakao.com/_IrGaxj" target="_blank">
              <KakaoChannelIcon />
            </KakaoChannel>
          </div>
        </Info>
        {!isMobile && (
          <MenuWrapper>
            <Menu
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              {t("footer.menu1")}
            </Menu>
            <Menu
              onClick={() =>
                serviceSummaryRef?.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {t("footer.menu2")}
            </Menu>
            <Menu
              onClick={() =>
                projectsRef?.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              {t("footer.menu3")}
            </Menu>
            <Link to={PATH_NAMES.CONTACT.path}>
              <Menu>{t("footer.menu4")}</Menu>
            </Link>
          </MenuWrapper>
        )}
      </Row>
      {!isMobile && <Line />}
      <Copyright>Copyright © Footsketch Co., Ltd.</Copyright>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.appMaxWidth,

  [theme.media.down["DESKTOP"]]: {
    padding: `0px ${theme.spacing.large}px`,
  },

  [theme.media.down["TABLET"]]: {
    padding: `0px ${theme.spacing.default}px`,
  },
}));

const Row = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: `${theme.spacing.section * 2}px 0px`,

  [theme.media.down["DESKTOP"]]: {
    paddingTop: theme.spacing.middle,
    paddingBottom: theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    paddingTop: theme.spacing.middle,
    paddingBottom: theme.spacing.section,
    flexDirection: "column-reverse",
  },
}));

const Info = styled.div<any>(() => ({
  flex: 1,
  textAlign: "left",
}));

const Logo = styled.img.attrs(() => ({
  src: logo,
}))<any>(({ theme }: ThemedComponentProps) => ({
  height: 56,
  objectFit: "contain",
  marginBottom: theme.spacing.middle,

  [theme.media.down["DESKTOP"]]: {
    height: 32,
    marginBottom: theme.spacing.regular / 2,
  },

  [theme.media.down["TABLET"]]: {
    height: 32,
    marginBottom: theme.spacing.default,
  },
}));

const Text = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  color: theme.color.white,
  fontSize: theme.font.body3,
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption2,
    marginBottom: theme.spacing.xSmall,
  },

  [theme.media.down["TABLET"]]: {
    marginBottom: theme.spacing.small,
  },
}));

const EmailWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.middle,
}));

const Email = styled.a<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
  color: theme.color.gold,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption2,
  },
}));

const KakaoChannel = styled.a<any>(() => ({
  display: "inline-block",
}));

const KakaoChannelIcon = styled.img.attrs(() => ({
  src: kakaoChannel,
}))<any>(({ theme }: ThemedComponentProps) => ({
  height: 40,
  objectFit: "contain",

  [theme.media.down["TABLET"]]: {
    height: 34,
  },
}));

const MenuWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "flex-end",

  [theme.media.down["TABLET"]]: {
    justifyContent: "center",
  },
}));

const Menu = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.large}px`,
  color: theme.color.white,
  fontSize: theme.font.body2,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.xSmall}px ${theme.spacing.regular}px`,
    fontSize: theme.font.caption2,
  },

  [theme.media.down["TABLET"]]: {
    flex: 1,
    padding: `${theme.spacing.small}px ${theme.spacing.default}px`,
    fontSize: theme.font.caption1,
  },
}));

const Line = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 1,
  backgroundColor: theme.color.squareBackground,
}));

const Copyright = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.middle}px 0px`,
  color: theme.color.gray1,
  fontSize: theme.font.body2,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption2,
  },
}));

export default Footer;
