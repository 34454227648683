import styled, { ThemedComponentProps } from "styled-components";

export const ContactSectionTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body2,
    fontWeight: "bold",
    textAlign: "left",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body3,
    },

    [theme.media.down["TABLET"]]: {
      fontSize: theme.font.body2,
    },
  })
);
